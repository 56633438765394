import { DocumentTextIcon, TrashIcon, ServerIcon, DocumentReportIcon, DotsVerticalIcon, CurrencyDollarIcon, LocationMarkerIcon, CogIcon, ShieldCheckIcon, CreditCardIcon, TruckIcon, ArchiveIcon, GiftIcon, AcademicCapIcon, UsersIcon, DeviceMobileIcon, MailIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, Fragment } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import _ from "underscore";
import labels from "../../constants/Driver";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import FileInput from "../../components/Form/FileInput";
import { Link } from "react-router-dom";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import DataTable from "../../components/MDataTable";
import configuration from '../../config';
import Summary from './profile/summary';
import Services from './profile/services';
import Vehicles from './profile/vehicles';
import Licence from './profile/licence';
import CommercialInsurance from './profile/commercialInsurance';
import PersonalInsurance from './profile/personalInsurance';
import Certification from './profile/certification';
import Trips from './profile/trips';
import { toast } from 'react-toastify';
import { Menu, Transition, RadioGroup } from '@headlessui/react'
import { Line } from "react-chartjs-2";
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  } from 'chart.js';
Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  );
  const memoryOptions = [
    { name: 'Passenger'},
    { name: 'Driver'},
  ]
// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const data2 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","July","Aug","Sep","Oct","Nov","Dec"],
    datasets: [
      {
        label: "Earnings",
        data: [33, 53, 85, 41, 44, 65, 100,33, 53, 85, 41, 44, 65,],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ]
  };

const projects = [
        { name: 'Earned Since Joining',
        imageUrl:
            '/image/coins.png',
        href: '#', 
        members: '$13,500', 
        bgColor: 'bg-gray-100' },

        { name: 'Jobs Completed',
        imageUrl:
            '/image/trophy.png',
        href: '#', 
        members: '102', 
        bgColor: 'bg-gray-100' },
    ]


let Id = "";
// List Settings
function View() {
    const [subNavigation, setSubNavigation] = useState([
        { name: "Summary ", href: "#", icon: DocumentReportIcon, current: true },
        { name: "Services ", href: "#", icon: ServerIcon, current: false },
        { name: "Vehicles ", href: "#", icon: TruckIcon, current: false },
        { name: "Licence Info ", href: "#", icon: DocumentTextIcon, current: false },
        { name: "Commercial Insurance Info  ", href: "#", icon: ShieldCheckIcon, current: false },
        { name: "Insurance Info  ", href: "#", icon: DocumentTextIcon, current: false },
        { name: "Other Certification Info ", href: "#", icon: AcademicCapIcon, current: false },
        { name: "Payment Info ", href: "#", icon: CreditCardIcon, current: false },
        { name: "Trips ", href: "#", icon: LocationMarkerIcon, current: false },
        { name: "Rewards ", href: "#", icon: GiftIcon, current: false },
        { name: "Memberships  ", href: "#", icon: UsersIcon, current: false },
        { name: "Earnings ", href: "#", icon: CurrencyDollarIcon, current: false },
        { name: "App Settings ", href: "#", icon: CogIcon, current: false },
    ]);
    
    const fieldArr6 = [
        { id: 0, title: "Card Number", name: "number", type: "TextInput" },
        { id: 1, title: "Card Holder Name", name: "name", type: "TextInput" },
        { id: 4, title: "MM/YYYY", name: "date", type: "TextInput" },
        { id: 5, title: "CVV", name: "cvv", type: "TextInput" },
    ];

    const [errors, setErrors] = useState({
        name: "",
        mobile: "",
        email: "",
        password: "",
        confirmpassword: "",
        role_id: "",
        status: "",
    });

    const [fields, setFields] = useState({});
    const [mem, setMem] = useState(memoryOptions[0])

    const [tab, setTab] = useState(0)
    function setTabMenu(index) {
        setTab(index)
        const navigate = subNavigation;
        _.each(navigate, (element, i) => {
            let currentState = false;
            if (i === index) {
                currentState = true;
            }
            navigate[i].current = currentState;
        });
        setSubNavigation(navigate);
    }
    const [driverData, setDriverData] = useState({})

    useEffect(() => {
        // console.log(props.location)
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({url:'driver/detail', params:{driver_id:Id}}).then((data) => {
          // console.log(data)
          if(data.status === 200){
            setDriverData(data.payload)
          }
        }).catch(error => {
          // console.log(error)
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
      }, []);
    return (
        <>
            <Breadcrumb module={labels.driverProfile} />
            <div className="py-6 sm:px-6">
                <div className="relative">
                    <section className="px-4" aria-labelledby="contact-heading">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                                    <div className="w-full md:flex md:items-center md:justify-between md:space-x-5 mb-6 mt-4">
                                        <div className="flex items-top space-x-5">
                                            <div className="flex-shrink-0">
                                                <div className="relative">
                                                    <img
                                                        className="h-16 w-16 rounded-full"
                                                        src={(driverData.profile_photo)?driverData.profile_photo:"/image/avatar.png"}
                                                        alt=""
                                                    />
                                                    <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div>
                                                <h1 className="text-2xl font-bold text-gray-900">{driverData.first_name} {driverData.last_name}</h1>
                                                <p class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">
                                                    <DeviceMobileIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {driverData.mobile_country_code} {driverData.mobile}</p>
                                                <p class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">
                                                    <MailIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {driverData.email}</p>
                                            </div>
                                        </div>
                                        <div className="items-end">
                                            <div className="flex items-center justify-between">
                                                <h2 className="text-sm font-medium text-gray-900">Switch User</h2>
                                            </div>

                                            <RadioGroup value={mem} onChange={setMem} className="mt-2">
                                                <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                                                <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                                                {memoryOptions.map((option) => (
                                                    <RadioGroup.Option
                                                    key={option.name}
                                                    value={option}
                                                    className={({ active, checked }) =>
                                                        classNames(
                                                        'cursor-pointer focus:outline-none',
                                                        active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                                                        checked
                                                            ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                                                            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                                        'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                                                        )
                                                    }
                                                    >
                                                    <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
                                                    </RadioGroup.Option>
                                                ))}
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </div>

                                    <div className="">
                                        <main className="relative">
                                            <div className="mx-auto">
                                                <div className="">
                                                    <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0">
                                                        <aside className="py-6 lg:col-span-2">
                                                            <nav className="space-y-1">
                                                                {subNavigation.map((item, index) => (
                                                                    <Link
                                                                        to="#"
                                                                        key={item.name}
                                                                        onClick={() => setTabMenu(index)}
                                                                        onKeyDown={() => setTabMenu(index)}
                                                                        className={classNames(
                                                                            item.current
                                                                                ? "bg-gray-200 text-gray-900 text-teal-700 hover:bg-teal-50 hover:text-teal-700 rounded-md"
                                                                                : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                                                            "group px-3 py-2 flex items-center text-base"
                                                                        )}
                                                                        aria-current={item.current ? "page" : null}
                                                                    >
                                                                        <item.icon
                                                                            className={classNames(
                                                                                item.current
                                                                                    ? "text-teal-500 group-hover:text-teal-500"
                                                                                    : "text-gray-400 group-hover:text-gray-500",
                                                                                "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                                                            )}
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span className="truncate">
                                                                            {item.name}
                                                                        </span>
                                                                    </Link>
                                                                ))}
                                                            </nav>
                                                        </aside>
                                                        {tab === 0 ? <Summary/> : ''}

                                                        {tab === 1 ? <Services/> : ''}

                                                        {tab === 2 ? <Vehicles/> : ''}

                                                        {tab === 3 ? <Licence/> : ''}

                                                        {tab === 4 ? <CommercialInsurance/>: ''}

                                                        {tab === 5 ? <PersonalInsurance/> : ''}

                                                        {tab === 6 ? <Certification/> : ''}

                                                        {tab === 7 ? <div className="md:pl-8 sm:py-7 col-span-10">
                                                            <section aria-labelledby="applicant-information-title">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:px-6">
                                                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                            Payment Info
                                                                        </h2>
                                                                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                                                    </div>
                                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                                        <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                                                                            Add Payment Info
                                                                        </h2>
                                                                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                                            {fieldArr6.map((field) => {
                                                                                return (
                                                                                    <div className="sm:col-span-3" key={field.id}>
                                                                                        <Label title={field.title} />
                                                                                        <div className="mt-1">
                                                                                            {field.type === "TextInput" ? (
                                                                                                <TextInput
                                                                                                    id={field.name}
                                                                                                    name={field.name}
                                                                                                    is_number={field.is_number || false}
                                                                                                    is_password={field.is_password || false}
                                                                                                />
                                                                                            ) : field.type === "FileInput" ? (
                                                                                                <FileInput
                                                                                                    id={field.name}
                                                                                                    name={field.name}
                                                                                                />
                                                                                            ) : null}
                                                                                        </div>
                                                                                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        <div className="pt-5 border_color_theme">
                                                                            <div className="flex justify-end">
                                                                                <BtnRedirect title="Cancel" path="/driver/driverprofile" />
                                                                                <BtnSaveRecord title="Save" path="/driverprofile" />
                                                                            </div>
                                                                        </div>
                                                                        <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                                                                            Payment Info
                                                                        </h2>
                                                                        <div className="grid grid-cols-2 gap-6">
                                                                            <div className="credit-card visa bg-indigo-600 selectable">
                                                                                <div className="credit">
                                                                                John Kartar
                                                                                </div>
                                                                                <div className="credit-card-last4">
                                                                                    4242
                                                                                </div>                                                                                
                                                                                <div className="credit-card-expiry">
                                                                                    08/25
                                                                                </div>
                                                                                <TrashIcon className="h-5 w-5 absolute right-2 top-2 cursor-pointer" aria-hidden="true" />
                                                                            </div>
                                                                            <div className="credit-card mastercard bg-indigo-600 selectable">
                                                                                <div className="credit">
                                                                                John Kartar
                                                                                </div>
                                                                                <div className="credit-card-last4">
                                                                                    4242
                                                                                </div>                                                                                
                                                                                <div className="credit-card-expiry">
                                                                                    08/25
                                                                                </div>
                                                                                <TrashIcon className="h-5 w-5 absolute right-2 top-2 cursor-pointer" aria-hidden="true" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div> : ''}


                                                        {tab === 8 ? <Trips/> : ''}

                                                        {tab === 9 ? <div className="md:pl-8 sm:py-7 col-span-10">
                                                            <section aria-labelledby="applicant-information-title">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:px-6">
                                                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                            Rewards
                                                                        </h2>
                                                                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                                                    </div>
                                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">                                                                                                                                                                                                                      
                                                                        Pending
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div> : ''}

                                                        {tab === 10 ? <div className="md:pl-8 sm:py-7 col-span-10">
                                                            <section aria-labelledby="applicant-information-title">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:px-6">
                                                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                            Memberships
                                                                        </h2>
                                                                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                                                    </div>
                                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">                                                                                                                                                                                                                      
                                                                        Pending
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div> : ''}

                                                        {tab === 11 ? <div className="md:pl-8 sm:py-7 col-span-10">
                                                            <section aria-labelledby="applicant-information-title">
                                                                <div className="bg-white shadow sm:rounded-lg">
                                                                    <div className="px-4 py-5 sm:px-6">
                                                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                                            Earnings
                                                                        </h2>
                                                                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                                                    </div>
                                                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">                                                                                                                                                                                                                      
                                                                        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                                                            {projects.map((project) => (
                                                                            <li key={project.name} className="col-span-1 flex shadow-sm rounded-md">
                                                                                <div
                                                                                className={classNames(
                                                                                    project.bgColor,
                                                                                    'flex-shrink-0 flex items-center justify-center w-16 text-white border text-sm font-medium rounded-l-md'
                                                                                )}
                                                                                >                                  
                                                                                <img className="h-5 w-5" src={project.imageUrl} alt="" />
                                                                                </div>
                                                                                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                                                                                    <div className="flex-1 px-4 py-2 text-sm truncate">
                                                                                        <p className="text-gray-600 font-medium hover:text-gray-600">
                                                                                        {project.name}
                                                                                        </p>
                                                                                        <p className="text-gray-900 text-lg">{project.members}</p>
                                                                                    </div>                              
                                                                                </div>
                                                                            </li>
                                                                            ))}
                                                                        </ul>

                                                                        <div className="shadow-lg mt-5 mb-5 chart-wrapper">
                                                                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 mb-5">
                                                                                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                                                                                    <div className="ml-4 mt-4">
                                                                                    <h3 className="text-lg leading-6 font-medium text-gray-900">My Earnings</h3>
                                                                                    <p className="mt-1 text-lg font-bold text-indigo-600">
                                                                                        $480.00
                                                                                    </p>
                                                                                    </div>
                                                                                    <div className="ml-4 mt-4 flex-shrink-0">
                                                                                        <Menu as="div" className="relative z-30 inline-block text-left">
                                                                                            <div>
                                                                                            <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                                                                                <span className="sr-only">Open options</span>
                                                                                                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </Menu.Button>
                                                                                            </div>

                                                                                            <Transition
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-100"
                                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                                enterTo="transform opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-75"
                                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                                leaveTo="transform opacity-0 scale-95"
                                                                                                >
                                                                                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                                    <div className="py-1">
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                className={classNames(
                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                'flex px-4 py-2 text-sm'
                                                                                                                )}
                                                                                                            >                                                                                                                
                                                                                                                <span>This Week</span>
                                                                                                            </a>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                className={classNames(
                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                'flex px-4 py-2 text-sm'
                                                                                                                )}
                                                                                                            >                                                                                                                
                                                                                                                <span>This Month</span>
                                                                                                            </a>
                                                                                                            )}
                                                                                                        </Menu.Item>

                                                                                                    </div>
                                                                                                </Menu.Items>
                                                                                            </Transition>
                                                                                        </Menu>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Line data={data2} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div> : ''}

                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default View;
