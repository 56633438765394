import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useState} from "react";
import labels from "../../constants/Role";
import configuration from '../../config';
import { toast } from 'react-toastify';

// For Add Role
function Add({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const fieldArr = [
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    {
      id: 1,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
    { id: 2, title: "Description", name: "description", type: "TextArea" },
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please Enter Title"
      flag = false
    }

    if (!fields.description) {
      error['description'] = "Please Enter Description"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'role/create', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/role')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  setFields({ ...fields, [field]: e.target.value })
}
  return (
    <>
      <Breadcrumb module={labels.addRole} />
      <AddEditSection title="Add Role" path="/role" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
