import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import DataTable from "../../../components/MDataTable";
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary() {
    // const [trips, setTrips] = useState([]);
    const columns = [
        { title: "ID", field: "trip_id"},
        {
          title: "Name",
          field: "first_name",
          render: (rowData) => (
            <span>
              {rowData.p_first_name} {rowData.p_last_name}
            </span>
          ),
        },
        {
          title: "Service",
          field: "trip_id",
          render: (rowData) => (
            <span>
              {rowData.service_info.title}
            </span>
          ),
        },
        { title: "Trip Date", field: "created_at" },
        {
          title: "Subtotal",
          field: "subtotal",
          render: (rowData) => (
            <span>
              {rowData.currency} {rowData.subtotal}
            </span>
          ),
        },
        { title: "KM ", field: "formatted_total_distance" },
        {
          title: "Total",
          field: "total",
          render: (rowData) => (
            <span>
              {rowData.currency} {rowData.total}
            </span>
          ),
        },
        {
          title: "Status",
          field: "status",
          render: (rowData) => (
            <span
              className={
                rowData.status === "completed"
                  ? "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800"
                  : "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800"
              }
            >
              {" "}
              {rowData.status}
            </span>
          ),
        },
    ];
    const [data, setData] = useState([]);
    const onSelectionChange = (e) => {
        // let idArr = pluck(e, 'user_id');
        // setIds(idArr)
    }
    useEffect(() => {
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({url:'trip/list', params:{driver_id:Id}}).then((data) => {
          if(data.status === 200){
            setData(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Trips
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">                                                                                                                                                                                                                      
                        <DataTable
                        columns={columns}
                        data={data}
                        onSelectionChange={onSelectionChange}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
