import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/DataBackup";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    {
      title: "ID",
      field: "id",
    },
    { title: "Name", field: "name" },
    { title: "Size", field: "size" },
    {
      title: "Restore",
      field: "url",
      render: (rowData) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="text-indigo-600 hover:text-indigo-900" onClick={() => handleRestore(rowData)}> Restore</a>
      ),
    },
    {
      title: "Download",
      field: "url",
      render: (rowData) => (
        <a className="text-indigo-600 hover:text-indigo-900" href={rowData.url} download> Download</a>
      ),
    },
    {
      title: "Remove",
      field: "url",
      render: (rowData) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="text-indigo-600 hover:text-indigo-900" onClick={() => handleDelete(rowData)}> Delete</a>
      ),
    },
    /* { title: "Role", field: "role" }, */
    /* { title: "Logout Date", field: "logout_date" },
    { title: "IP Address", field: "ip" }, */
    /* { title: "Device", field: "device" }, */
  ];

  const btnGroups = [{ title: "Create Backup", type: "makeActive", path: "/backup" },];
  const [data, setData] = useState([]);

  function handleAction(type) {
    console.log(type)
    configuration.postAPI({url:'db-backup/create', params:{}}).then((data) => {
      if(data.status === 200){
        setData(data.payload)
        return toast.success("Database Backup Created Successfully");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleDelete(row){
    configuration.postAPI({url:'db-backup/remove', params:{filename:row.filename, url:row.url}}).then((data) => {
      if(data.status === 200){
        setData(data.payload)
        return toast.success("Database Backup Deleted Successfully");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  
  function handleRestore(row){
    configuration.postAPI({url:'db-backup/restore', params:{filename:row.filename, url:row.url}}).then((data) => {
      if(data.status === 200){
        setData(data.payload)
        return toast.success("Database Backup Restore Successfully");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  
  useEffect(() => {
    configuration.getAPI({url:'db-backup/list', params:{}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.dataBackup} />
      <ListSection
        captionTitle="View Database Backup"
        columns={columns}
        data={data}
        handleAction={handleAction}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
