import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useState} from "react";
import labels from "../../constants/Driver";
import configuration from '../../config';
import { toast } from 'react-toastify';

// For Add driver
function Add({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },  
  ];
  const fieldArr = [
    { id: 0, title: "First Name ", name: "first_name", type: "TextInput" },
    { id: 1, title: "Last Name", name: "last_name", type: "TextInput" },
    { id: 2, title: "Email", name: "email", type: "TextInput" },    
    { id: 3, title: "Phone Number", name: "mobile", type: "TextInput" },
    { id: 4, title: "Password ", name: "password", type: "TextInput", is_password: true },
    { id: 5, title: "Confirm Password  ", name: "confirmpassword", type: "TextInput", is_password: true },
    { id: 6, title: "Photo", name: "profile_photo", type: "FileInput" },
    {
      id: 7,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ];
  const [fields, setFields] = useState({});
  const [profile_photo, setPhoto] = useState(null);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.first_name) {
      error['first_name'] = "Please Enter first name"
      flag = false
    }

    if (!fields.last_name) {
      error['last_name'] = "Please Enter last name"
      flag = false
    }

    if (!fields.email) {
      error['email'] = "Please Enter email"
      flag = false
    }

    if (!fields.mobile) {
      error['mobile'] = "Please Enter mobile"
      flag = false
    }

    if (!fields.password) {
      error['password'] = "Please Enter password"
      flag = false
    } else {
      let re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
      if(!re.test(fields.password)){
        error['password'] = "Password must be 8 characters long including uppercase , lowercase and symbol."
        flag = false
      }
    }

    if (!fields.confirmpassword) {
      error['confirmpassword'] = "Please Enter confirm password"
      flag = false
    }
    
    if (fields.confirmpassword !== fields.password) {
      error['confirmpassword'] = "Password and confirm password doesn't match."
      flag = false
    }

    if (!fields.profile_photo) {
      error['profile_photo'] = "Please Select Photo"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      const formData = new FormData();
      formData.append('profile_photo', profile_photo);
      formData.append('fields', JSON.stringify({...fields, lang_code:'EN'}));
      configuration.postFormDataAPI({url:'app-user/create', params:formData}).then((data) => {
        if(data.status === 200){
          history.push('/driver')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  if(field === 'profile_photo'){
    setPhoto(e.target.files[0]);
    setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
  } else {
    setFields({ ...fields, [field]: e.target.value })
  }
}
  function cancleImage(field, e) {
    setPhoto(null);
    setFields({ ...fields, [field]: '' })
  }
  return (
    <>
      <Breadcrumb module={labels.AddDriver} />
      <AddEditSection title="Add App Users" path="/driver" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} cancleImage={cancleImage} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
