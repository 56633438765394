import React, { useState, useEffect } from "react";
import { TrashIcon} from "@heroicons/react/outline";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import configuration from '../../../config';
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary() {
    const fieldArr2 = [
        
        { id: 1, title: "Title", name: "title", type: "TextInput" },
        { id: 2, title: "Upload Picture of Certificate", name: "certificate_photo", type: "FileInput" },
        
    ];
    const [Licence, setLicence] = useState([]);
    const [fields, setFields] = useState({});
    const [certificate_photo, setLicenseFront] = useState(null);
    const [errors, setErrors] = useState({
        certificate_photo: "",
        title: "",
    });
    useEffect(() => {
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        
        configuration.getAPI({url:'driver/list-certificate', params:{driver_id:Id}}).then((data) => {
          // console.log(data)
          if(data.status === 200 && data.payload && data.payload.length > 0){
            setLicence(data.payload);
          }
        }).catch(error => {
          // console.log(error)
          return toast.error(error.message)
        });
    }, []);

    function handleChange(field, e) {
        if(field === 'certificate_photo'){
            setLicenseFront(e.target.files[0]);
            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
        } else {
            setFields({ ...fields, [field]: e.target.value })
        }
    }
    function cancleImage(field, e) {
        setLicenseFront(null);
        setFields({ ...fields, [field]: '' })
    }
    function handleDelete(certificateId) {
        const formData = new FormData();
        formData.append('fields', JSON.stringify({lang_code:'EN', type:'delete', user_id:Id, certificate_id:certificateId}));
        configuration.postFormDataAPI({url:'driver/add-certificate', params:formData}).then((data) => {
            if(data.status === 200){
              // history.push('/driver')
              setLicence(data.payload);
              return toast.success('Delete successfully')
            } else if (data.status === 404) {
              return toast.error(data.error.message)
            } else {
              return toast.error('Something went wrong')
            }
        }).catch(error => {
            return toast.error(error.message)
        });
    }
    function validation() {
        let flag = true;
        let error = {}
        if (!fields.certificate_photo) {
          error['certificate_photo'] = "Please select certificate photo"
          flag = false
        }

        if (!fields.title) {
          error['title'] = "Please enter title"
          flag = false
        }
        
        setErrors({...error })
        return flag;
    }
    const handleSubmit = () =>{
        if (validation()) {
            console.log(fields)
            const formData = new FormData();
            if(certificate_photo){
                formData.append('certificate_photo', certificate_photo);
            }
            formData.append('fields', JSON.stringify({...fields, lang_code:'EN', type:'add', user_id:Id}));
            configuration.postFormDataAPI({url:'driver/add-certificate', params:formData}).then((data) => {
                if(data.status === 200){
                  // history.push('/driver')
                  setFields({title:'', certificate_photo:''});
                  setLicenseFront(null);
                  setLicence(data.payload);
                  return toast.success('Update successfully')
                } else if (data.status === 404) {
                  return toast.error(data.error.message)
                } else {
                  return toast.error('Something went wrong')
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
        <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        Certification Info 
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                        Add Certification
                    </h2>
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {fieldArr2.map((field) => {
                            return (
                                <div className="sm:col-span-3" key={field.id}>
                                    <Label title={field.title} />
                                    <div className="mt-1">
                                        {field.type === "TextInput" ? (
                                            <TextInput
                                                id={field.name}
                                                name={field.name}
                                                handleChange={handleChange}
                                                value={fields[field.name]}
                                                is_number={field.is_number || false}
                                                is_password={field.is_password || false}
                                            />
                                        ) : field.type === "FileInput" ? (
                                            <FileInput
                                                id={field.name}
                                                name={field.name}
                                                value={fields[field.name]}
                                                handleChange={handleChange}
                                                cancleImage={cancleImage}
                                            />
                                        ) : null}
                                    </div>
                                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                </div>
                            );
                        })}
                    </div>
                    <div className="pt-5 border_color_theme">
                        <div className="flex justify-end">
                            <BtnSaveRecord handleSubmit={handleSubmit} title="Save" path="/driverprofile" />
                        </div>
                    </div>
                    
                    <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                        Certification
                    </h2>

                    <ul role="list" className="grid grid-cols-2 gap-6">
                        {Licence.map((licence) => (
                            <li key={licence.certificate_id} className="flex py-6 sm:py-6 px-4 shadow-lg rounded-md">
                                <div className="flex-shrink-0">
                                    <h3>{licence.title}</h3>
                                    <img
                                        src={licence.certificate_photo}
                                        alt={licence.title}
                                        className="w-12/12 rounded-md"
                                    />
                                </div>

                                <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">                                                                                        
                                        <div className="mt-4 sm:mt-0">                                                                                                
                                            <div className="absolute top-0 right-0">
                                                <button type="button" onClick={(e)=>handleDelete(licence.certificate_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                                                    <span className="sr-only">Remove</span>
                                                    <TrashIcon className="h-5 w-5 " aria-hidden="true" />
                                                </button>
                                            </div>                                                                                                
                                        </div>
                                    </div>

                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    </div>
    );
}

export default Summary;
