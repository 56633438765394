import React, { useState, useEffect } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Caption from "../../components/Caption";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextArea from "../../components/Form/TextArea";
import FileInput from "../../components/Form/FileInput";
import SwitchInput from "../../components/Form/SwitchInput";
import TextInput from "../../components/Form/TextInput";
import labels from "../../constants/User";
import configuration from "../../config";
import CountryCode from "../../country_code";
import { toast } from "react-toastify";
import { each } from "underscore";
import { reactLocalStorage } from "reactjs-localstorage";
var jwt = require("jsonwebtoken");
// For Add User
function Add({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const [roleOpt, setRoleOpt] = useState([]);
  const fieldArr = [
    { id: 0, title: "First Name", name: "first_name", type: "TextInput" },
    { id: 0, title: "Last Name", name: "last_name", type: "TextInput" },
    {
      id: 0,
      title: "Country Code",
      name: "mobile_country_code",
      type: "Dropdown",
      options: CountryCode.countries,
    },
    {
      id: 1,
      title: "Mobile",
      name: "mobile",
      type: "TextInput",
      is_number: true,
    },
    { id: 4, title: "Email", name: "email", type: "TextInput" },
    {
      id: 5,
      title: "Password",
      name: "password",
      type: "TextInput",
      is_password: true,
    },
    {
      id: 6,
      title: "Confirm Password",
      name: "confirmpassword",
      type: "TextInput",
      is_password: true,
    },
    {
      id: 7,
      title: "Role",
      name: "role_id",
      type: "Dropdown",
      options: roleOpt,
    },
    { id: 0, title: "Photo", name: "photo", type: "FileInput" },
    {
      id: 2,
      title: "Two Step Verification",
      name: "is_two_step_verification",
      type: "SwitchInput",
    },
    {
      id: 9,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];

  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirmpassword: "",
    role_id: "",
    status: "",
  });
  const [photo, setPhoto] = useState(null);
  const [fields, setFields] = useState({});

  function validation() {
    let flag = true;
    let error = {};
    if (!fields.first_name) {
      error["first_name"] = "Please enter valid first name";
      flag = false;
    }

    if (!fields.last_name) {
      error["last_name"] = "Please enter valid last name";
      flag = false;
    }

    if (!fields.mobile) {
      error["mobile"] = "Please enter valid mobile";
      flag = false;
    }
    if (
      !/^[0-9]{11}$/.test(fields.mobile) &&
      !/^[0-9]{10}$/.test(fields.mobile)
    ) {
      error["mobile"] = "Please insert valid phone number!!!";
      flag = false;
    }
    if (!fields.email) {
      error["email"] = "Please enter valid email";
      flag = false;
    }
    if (fields.email) {
      const emailRegexp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (!emailRegexp.test(fields.email)) {
        error["email"] = "Please enter valid email";
        flag = false;
      }
    }
    if (!fields.password) {
      error["password"] = "Please enter password";
      flag = false;
    }
    if (!fields.confirmpassword) {
      error["confirmpassword"] = "Please enter confirm password";
      flag = false;
    }
    if (fields.confirmpassword !== fields.password) {
      error["confirmpassword"] = "Password and confirm password doesn't match.";
      flag = false;
    }
    // if (!fields.photo) {
    //   error['photo'] = "Please select Photo"
    //   flag = false
    // }
    if (!fields.role_id) {
      error["role_id"] = "Please select role";
      flag = false;
    }
    if (!fields.status) {
      error["status"] = "Please select Status";
      flag = false;
    }
    // if (!fields.photo) {
    //   error["photo"] = "Please select profile";
    //   flag = false;
    // }
    setErrors({ ...error });
    return flag;
  }

  const handleSubmit = () => {
    if (validation()) {
      // console.log("fields");
      // console.log(fields);
      const formData = new FormData();
      if (photo) {
        formData.append("photo", photo);
      }
      formData.append("fields", JSON.stringify({ ...fields, lang_code: "EN" }));
      configuration
        .postFormDataAPI({ url: "user/create", params: formData })
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            history.push("/user");
            toast.success("Record Added Successfully");

            // setData(data.payload)
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          // console.log(error)
          return toast.error(error.message);
        });
    }
  };

  function getRole() {
    configuration
      .getAPI({ url: "role/list", params: { status: "active" } })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.value = element.role_id;
              element.id = element.role_id;
              return element;
            });
          }
          console.log(data.payload);
          setRoleOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  useEffect(() => {
    getRole();
  }, []);

  function handleChange(field, e) {
    var hasNumber = /\d/;
    if (field === "first_name") {
      if (hasNumber.test(e.target.value)) {
      } else {
        setFields({ ...fields, [field]: e.target.value });
      }
      return;
    }
    if (field === "last_name") {
      if (hasNumber.test(e.target.value)) {
      } else {
        setFields({ ...fields, [field]: e.target.value });
      }
      return;
    }
    if (field === "photo") {
      setPhoto(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) });
    } else if (field === "is_two_step_verification") {
      setFields({ ...fields, [field]: e });
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
  }

  function cancleImage(field, e) {
    setPhoto(null);
    setFields({ ...fields, [field]: "" });
  }

  return (
    <>
      <Breadcrumb module={labels.addUsers} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Add User" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              {fieldArr.map((field) => {
                                return (
                                  <div className="sm:col-span-3" key={field.id}>
                                    <Label title={field.title} />
                                    <div className="mt-1">
                                      {field.type === "TextInput" ? (
                                        <TextInput
                                          id={field.name}
                                          handleChange={handleChange}
                                          value={fields?.[field.name]}
                                          name={field.name}
                                          is_number={field.is_number || false}
                                          is_password={
                                            field.is_password || false
                                          }
                                        />
                                      ) : field.type === "FileInput" ? (
                                        <FileInput
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields?.[field.name]}
                                          cancleImage={cancleImage}
                                        />
                                      ) : field.type === "Dropdown" ? (
                                        <Dropdown
                                          title={field.title}
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields?.[field.name]}
                                          status={field.options || []}
                                        />
                                      ) : field.type === "SwitchInput" ? (
                                        <SwitchInput
                                          id={field.name}
                                          name={field.name}
                                          handleChange={handleChange}
                                          value={fields?.[field.name]}
                                        />
                                      ) : field.type === "TextArea" ? (
                                        <TextArea
                                          id={field.name}
                                          name={field.name}
                                        />
                                      ) : null}
                                    </div>
                                    {errors[field.name] ? (
                                      <Label
                                        title={field.title}
                                        fieldError={errors[field.name]}
                                      />
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="pt-5 border_color_theme">
                          <div className="flex justify-end">
                            <BtnRedirect title="Cancel" path="/user" />
                            <BtnSaveRecord
                              handleSubmit={handleSubmit}
                              title="Submit"
                              path="/user"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Add;
