import { BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import {reactLocalStorage} from 'reactjs-localstorage';

// Add Components
import AddCity from "./pages/City/Add";
import AddCms from "./pages/CMS/Add";
import AddCountry from "./pages/Country/Add";
import AddCurrency from "./pages/Currency/Add";
import AddEmailTemplate from "./pages/EmailTemplate/Add";
import AddHelp from "./pages/Help/Add";
import AddHelpCategory from "./pages/HelpCategory/Add";
import AddLabel from "./pages/Label/Add";
import AddLanguage from "./pages/Language/Add";
import AddPushTemplate from "./pages/PushTemplate/Add";
import AddRole from "./pages/Role/Add";
import AddPassenger from "./pages/Passenger/Add";
import AddServices from "./pages/Services/Add";
import AddVehicleMake from "./pages/VehicleMake/Add";
import AddVehicleModel from "./pages/VehicleModel/Add";
import AddVehicleYear from "./pages/VehicleYear/Add";
import AddRideOption from "./pages/RideOption/Add";
import AddMobilityOption from "./pages/MobilityOption/Add";
import AddDriver from "./pages/Driver/Add";
import AddAppUser from "./pages/AppUser/Add";
import AddSmsTemplate from "./pages/SmsTemplate/Add";
import AddState from "./pages/State/Add";
import AddTax from "./pages/Tax/Add";
import AddUsers from "./pages/User/Add";
import AddZipcode from "./pages/Zipcode/Add";
import AddDriverMembership from "./pages/DriverMembership/Add";
import AddPassengerMembership from "./pages/PassengerMembership/Add";
import AddCancelReason from "./pages/CancelReason/Add";
import AddCoupon from "./pages/Coupon/Add";
import AddPreference from "./pages/Preference/Add";

// Edit Components
import EditCity from "./pages/City/Edit";
import EditCms from "./pages/CMS/Edit";
import EditCountry from "./pages/Country/Edit";
import EditCurrency from "./pages/Currency/Edit";
import EditEmailTemplate from "./pages/EmailTemplate/Edit";
import EditHelp from "./pages/Help/Edit";
import EditHelpCategory from "./pages/HelpCategory/Edit";
import EditLabel from "./pages/Label/Edit";
import EditLanguage from "./pages/Language/Edit";
import EditPushTemplate from "./pages/PushTemplate/Edit";
import EditRole from "./pages/Role/Edit";
import EditPassenger from "./pages/Passenger/Edit";
import EditServices from "./pages/Services/Edit";
import EditVehicleMake from "./pages/VehicleMake/Edit";
import EditVehicleModel from "./pages/VehicleModel/Edit";
import EditVehicleYear from "./pages/VehicleYear/Edit";
import EditRideOption from "./pages/RideOption/Edit";
import EditMobilityOption from "./pages/MobilityOption/Edit";
import EditDriver from "./pages/Driver/Edit";
import EditAppUser from "./pages/AppUser/Edit";
import DriverProfile from "./pages/Driver/DriverProfile";
import UserProfile from "./pages/AppUser/UserProfile";
import EditSmsTemplate from "./pages/SmsTemplate/Edit";
import EditState from "./pages/State/Edit";
import EditTax from "./pages/Tax/Edit";
import EditUsers from "./pages/User/Edit";
import EditZipcode from "./pages/Zipcode/Edit";
import EditDriverMembership from "./pages/DriverMembership/Edit";
import EditPassengerMembership from "./pages/PassengerMembership/Edit";
import EditCancelReason from "./pages/CancelReason/Edit";
import EditCoupon from "./pages/Coupon/Edit";
import EditPreference from "./pages/Preference/Edit";

// List Components
import City from "./pages/City/View";
import Cms from "./pages/CMS/View";
import Country from "./pages/Country/View";
import Currency from "./pages/Currency/View";
import Dashboard from "./pages/Dashboard/View";
import Sales from "./pages/Sales/View";
import Orders from "./pages/Orders/View";
import EmailTemplate from "./pages/EmailTemplate/View";
import ForgotPassword from "./pages/ForgotPassword/View";
import GuestRoute from "./layout/GuestRoute";
import Help from "./pages/Help/View";
import HelpCategory from "./pages/HelpCategory/View";
import Label from "./pages/Label/View";
import Language from "./pages/Language/View";
import LoginLog from "./pages/LoginLog/View";
import DataBackup from "./pages/DataBackup/View";
import Permission from "./pages/AccessRight/View";
import PersonalSettings from "./pages/Setting/View";
import PushTemplate from "./pages/PushTemplate/View";
import Role from "./pages/Role/View";
import Passenger from "./pages/Passenger/View";
import Services from "./pages/Services/View";
import VehicleMake from "./pages/VehicleMake/View";
import VehicleModel from "./pages/VehicleModel/View";
import VehicleYear from "./pages/VehicleYear/View";
import VehicleImport from "./pages/VehicleImport/View";
import RideOption from "./pages/RideOption/View";
import MobilityOption from "./pages/MobilityOption/View";
import Price from "./pages/Price/View";
import Driver from "./pages/Driver/View";
import AppUser from "./pages/AppUser/View";
import Setting from "./pages/Setting/Menu";
import SignIn from "./pages/SignIn/View";
import Reset from "./pages/Reset/View";
import OTP from "./pages/OTP/View";
import SmsTemplate from "./pages/SmsTemplate/View";
import State from "./pages/State/View";
import Tax from "./pages/Tax/View";
import TemplateRoute from "./layout/TemplateRoute";
import Users from "./pages/User/View";
import Zipcode from "./pages/Zipcode/View";
import Trip from "./pages/Trips/View";
import TripsDetail from "./pages/Trips/TripsDetail";
import TripReports from "./pages/Trips/TripReports";
import BusinessTrip from "./pages/BusinessTrips/View";
import BusinessTripsDetail from "./pages/BusinessTrips/TripsDetail";
import DriverMembership from "./pages/DriverMembership/View";
import PassengerMembership from "./pages/PassengerMembership/View";
import CancelReason from "./pages/CancelReason/View";
import Coupon from "./pages/Coupon/View";
import Preference from "./pages/Preference/View";
import WebUsers from "./pages/WebUsers/View";
import about from "./pages/Frontend/About";
import faq from "./pages/Frontend/Faq";

// Manage Routes
function App() {
  useEffect(() => {
    const dark_mode = document.getElementById('style-direction');
    dark_mode.href = (reactLocalStorage.get('dark_mode') === 'yes')?'/css/tailwind-dark.css':'/css/tailwind-light.css';

    const css_name = document.getElementById('style-color');
    css_name.href = `/css/${(reactLocalStorage.get('css_name'))?reactLocalStorage.get('css_name'):'white'}.css`;

  }, []);
  return (
    <main>
      <Router>
        <Switch>
          <GuestRoute exact path="/" component={SignIn} />
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute exact path="/otp" component={OTP} />
          <GuestRoute exact path="/reset" component={Reset} />
          <GuestRoute exact path="/about" component={about} />
          <GuestRoute exact path="/faq" component={faq} />
          <TemplateRoute exact path="/dashboard" component={Dashboard} />
          <TemplateRoute exact path="/sales" component={Sales} />
          <TemplateRoute exact path="/orders" component={Orders} />
          <TemplateRoute exact path="/setting" component={Setting} />
          <TemplateRoute
            exact
            path="/personal-settings"
            component={PersonalSettings}
          />
          <TemplateRoute exact path="/role" component={Role} />
          <TemplateRoute exact path="/role/add" component={AddRole} />
          <TemplateRoute exact path="/role/edit/:id" component={EditRole} />
          <TemplateRoute exact path="/passenger" component={Passenger} />
          <TemplateRoute exact path="/passenger/add" component={AddPassenger} />
          <TemplateRoute exact path="/passenger/edit/:id" component={EditPassenger} />
          <TemplateRoute exact path="/services" component={Services} />
          <TemplateRoute exact path="/services/add" component={AddServices} />
          <TemplateRoute exact path="/services/edit/:id" component={EditServices} />
          <TemplateRoute exact path="/vehiclemake" component={VehicleMake} />
          <TemplateRoute exact path="/vehiclemake/add" component={AddVehicleMake} />
          <TemplateRoute exact path="/vehiclemake/edit/:id" component={EditVehicleMake} />
          <TemplateRoute exact path="/vehiclemodel" component={VehicleModel} />
          <TemplateRoute exact path="/vehiclemodel/add" component={AddVehicleModel} />
          <TemplateRoute exact path="/vehiclemodel/edit/:id" component={EditVehicleModel} />
          <TemplateRoute exact path="/vehicleyear" component={VehicleYear} /> 
          <TemplateRoute exact path="/vehicleimport" component={VehicleImport} /> 
          <TemplateRoute exact path="/vehicleyear/add" component={AddVehicleYear} />
          <TemplateRoute exact path="/vehicleyear/edit/:id" component={EditVehicleYear} />
          <TemplateRoute exact path="/rideoption" component={RideOption} />
          <TemplateRoute exact path="/rideoption/add" component={AddRideOption} />
          <TemplateRoute exact path="/rideoption/edit/:id" component={EditRideOption} />

          <TemplateRoute exact path="/mobilityoption" component={MobilityOption} />
          <TemplateRoute exact path="/mobilityoption/add" component={AddMobilityOption} />
          <TemplateRoute exact path="/mobilityoption/edit/:id" component={EditMobilityOption} />

          <TemplateRoute exact path="/driver" component={Driver} />
          <TemplateRoute exact path="/driver/add" component={AddDriver} />
          <TemplateRoute exact path="/driver/edit/:id" component={EditDriver} />
          <TemplateRoute exact path="/driver/driverprofile/:id" component={DriverProfile} />

          <TemplateRoute exact path="/app-user" component={AppUser} />
          <TemplateRoute exact path="/app-user/add" component={AddAppUser} />
          <TemplateRoute exact path="/app-user/edit" component={EditAppUser} />
          <TemplateRoute exact path="/app-user/user-profile" component={UserProfile} />

          <TemplateRoute exact path="/language" component={Language} />
          <TemplateRoute exact path="/language/add" component={AddLanguage} />
          <TemplateRoute
            exact
            path="/language/edit/:id"
            component={EditLanguage}
          />
          <TemplateRoute exact path="/label" component={Label} />
          <TemplateRoute exact path="/label/add" component={AddLabel} />
          <TemplateRoute exact path="/label/edit/:id" component={EditLabel} />
          <TemplateRoute exact path="/tax" component={Tax} />
          <TemplateRoute exact path="/tax/add" component={AddTax} />
          <TemplateRoute exact path="/tax/edit/:id" component={EditTax} />
          <TemplateRoute exact path="/country" component={Country} />
          <TemplateRoute exact path="/country/add" component={AddCountry} />
          <TemplateRoute
            exact
            path="/country/edit/:id"
            component={EditCountry}
          />
          <TemplateRoute exact path="/state" component={State} />
          <TemplateRoute exact path="/state/add" component={AddState} />
          <TemplateRoute exact path="/state/edit/:id" component={EditState} />
          <TemplateRoute exact path="/city" component={City} />
          <TemplateRoute exact path="/city/add" component={AddCity} />
          <TemplateRoute exact path="/city/edit/:id" component={EditCity} />
          <TemplateRoute exact path="/zipcode" component={Zipcode} />
          <TemplateRoute exact path="/zipcode/add" component={AddZipcode} />
          <TemplateRoute
            exact
            path="/zipcode/edit/:id"
            component={EditZipcode}
          />
          <TemplateRoute exact path="/help-category" component={HelpCategory} />
          <TemplateRoute
            exact
            path="/help-category/add"
            component={AddHelpCategory}
          />
          <TemplateRoute
            exact
            path="/help-category/edit/:id"
            component={EditHelpCategory}
          />
          <TemplateRoute exact path="/help" component={Help} />
          <TemplateRoute exact path="/help/add" component={AddHelp} />
          <TemplateRoute exact path="/help/edit/:id" component={EditHelp} />
          <TemplateRoute exact path="/cms" component={Cms} />
          <TemplateRoute exact path="/cms/add" component={AddCms} />
          <TemplateRoute exact path="/cms/edit/:id" component={EditCms} />
          <TemplateRoute exact path="/currency" component={Currency} />
          <TemplateRoute exact path="/currency/add" component={AddCurrency} />
          <TemplateRoute
            exact
            path="/currency/edit/:id"
            component={EditCurrency}
          />
          <TemplateRoute
            exact
            path="/email-template"
            component={EmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/add"
            component={AddEmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/edit/:id"
            component={EditEmailTemplate}
          />
          <TemplateRoute exact path="/push-template" component={PushTemplate} />
          <TemplateRoute
            exact
            path="/push-template/add"
            component={AddPushTemplate}
          />
          <TemplateRoute
            exact
            path="/push-template/edit/:id"
            component={EditPushTemplate}
          />
          <TemplateRoute exact path="/sms-template" component={SmsTemplate} />
          <TemplateRoute
            exact
            path="/sms-template/add"
            component={AddSmsTemplate}
          />
          <TemplateRoute
            exact
            path="/sms-template/edit/:id"
            component={EditSmsTemplate}
          />
          <TemplateRoute exact path="/user" component={Users} />
          <TemplateRoute exact path="/user/add" component={AddUsers} />
          <TemplateRoute exact path="/user/edit/:id" component={EditUsers} />
          <TemplateRoute exact path="/log" component={LoginLog} />
          <TemplateRoute exact path="/data-backup" component={DataBackup} />
          <TemplateRoute exact path="/permission" component={Permission} />
          <TemplateRoute exact path="/price" component={Price} />
          <TemplateRoute exact path="/trips" component={Trip} />
          <TemplateRoute exact path="/tripsdetail" component={TripsDetail} />
          <TemplateRoute exact path="/tripreports" component={TripReports} />
          <TemplateRoute exact path="/business-trips" component={BusinessTrip} />
          <TemplateRoute exact path="/business-tripsdetail" component={BusinessTripsDetail} />
          <TemplateRoute exact path="/drivermembership" component={DriverMembership} />
          <TemplateRoute exact path="/drivermembership/add" component={AddDriverMembership} />
          <TemplateRoute exact path="/drivermembership/edit/:id" component={EditDriverMembership} />

          <TemplateRoute exact path="/passengermembership" component={PassengerMembership} />
          <TemplateRoute exact path="/passengermembership/add" component={AddPassengerMembership} />
          <TemplateRoute exact path="/passengermembership/edit/:id" component={EditPassengerMembership} />

          <TemplateRoute exact path="/cancel-reason" component={CancelReason} />
          <TemplateRoute exact path="/cancel-reason/add" component={AddCancelReason} />
          <TemplateRoute exact path="/cancel-reason/edit/:id" component={EditCancelReason} />

          <TemplateRoute exact path="/coupon" component={Coupon} />
          <TemplateRoute exact path="/coupon/add" component={AddCoupon} />
          <TemplateRoute exact path="/coupon/edit/:id" component={EditCoupon} />
          
          <TemplateRoute exact path="/preference" component={Preference} />
          <TemplateRoute exact path="/preference/add" component={AddPreference} />
          <TemplateRoute exact path="/preference/edit/:id" component={EditPreference} />
          
          <TemplateRoute exact path="/web-users" component={WebUsers} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
