import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useState, useEffect} from "react";
import labels from "../../constants/DriverMembership";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// For Add subscription
function Add({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive e", value: "inactive" },
  ];
  const [fieldArr, setFieldArr] = useState([
    { id: 0, title: "Code ", name: "code", type: "TextInput" },
    { id: 1, title: "Price ", name: "price", type: "TextInput" },
    { id: 4, title: "Trial Days ", name: "trial_days", type: "TextInput", is_number:true },
    { id: 5, title: "Refered Passenger Complete Ride Criteria", name: "refered_passenger_complete_ride", type: "TextInput", is_number:true },
    { id: 6, title: "Referal Passenger Credit ", name: "refer_passenger_credit", type: "TextInput", is_number:true },
    { id: 7, title: "Referal Driver Credit ", name: "refer_driver_credit", type: "TextInput", is_number:true },
    {
      id: 8,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ]);
  const [fields, setFields] = useState({});
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  useEffect(() => {
    configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'subscriptions' } }).then((data) => {
      if (data.status === 200) {
        let orderNoArr = []
        each(data.payload, (singleRec) => {
          orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
        })
        const orderNoObj = {
          id: 10,
          title: "Order No",
          name: "order_no",
          type: "Dropdown",
          options: orderNoArr,
        }
        fieldArr.push(orderNoObj)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 13 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 16 + index,
            title: `Short Description [${language.title}]`,
            name: `short_desc_${language.code}`,
            type: "TextInput"
          });
        })
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 9 + index,
            title: `Long Description [${language.title}]`,
            name: `long_desc_${language.code}`,
            type: "TextArea"
          });
        })
        setFieldArr([...fieldArr, ...fieldArr_data])
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.price) {
      error['price'] = "Please enter price"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please enter code"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'subscription/create', params:{...fields, type:'driver'}}).then((data) => {
        if(data.status === 200){
          history.push('/drivermembership')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  setFields({ ...fields, [field]: e.target.value })
}
  return (
    <>
      <Breadcrumb module={labels.AddDriverMembership} />
      <AddEditSection title="Add Driver Membership" path="/drivermembership" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
