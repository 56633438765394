import { createDecipheriv } from 'crypto';
const baseURL =    'https://www.devapi.tourchride.com/backend/'
const apiBaseURL = 'https://www.devapi.tourchride.com/backend/'

//const baseURL =    'http://testapi.tourchride.com:9239/backend/'
//const apiBaseURL = 'http://testapi.tourchride.com:9239/backend/'

const algorithm = 'aes-256-cbc';

const config = {
  appName: 'Tourch',
  tinyAPIKey: "wyycsar13boveop4pt321htnkjzvcmsvvvasi4ve32o06lyo",//process.env.REACT_APP_TINY_API_KEY,
  baseURL,
  apiBaseURL,
 
  decrypt (Securitykey, iv, data) {
    return new Promise((resolve) => {
        try{
          const decipher = createDecipheriv(algorithm, Securitykey, iv);
          let decryptedData = decipher.update(data, "hex", "utf-8");
          decryptedData += decipher.final("utf8");
          resolve(decryptedData)
          return;
        }
        catch(err){
          // console.log(err)
        }
    })
},

  getAPI(data) {
    return new Promise((resolve, reject) => {
      let url = new URL(`${baseURL}${data.url}`)
      // url.search = new URLSearchParams({...data.params, code:'EN'}).toString();
      url.search = new URLSearchParams({...data.params, lang_code:'EN'}).toString();
      fetch(url).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
  postAPI(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}${data.url}`, {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body:JSON.stringify({...data.params, code:'EN'})
        body:JSON.stringify({...data.params, lang_code:'EN'})
      }).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
  postFormDataAPI(data) {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}${data.url}`, {
        method: "post",
        headers: {
          'contentType': "application/json",
        },
        body:data.params
      }).then((response) => {
        return response.json();
      }).then((data) => {
        // console.log(data.payload)
        // const response = this.decrypt(Securitykey, data.payload.iv, data.payload.data);
        resolve(data)
        return;
      }).catch(error => {
        // console.log(error)
        reject(error)
        return;
      });
    })
  },
};

export default config;
