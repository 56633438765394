
import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../../parts/Breadcrumb";
import Caption from "../../components/Caption";
import labels from "../../constants/Trip";
import { PaperAirplaneIcon, DownloadIcon,EyeIcon } from '@heroicons/react/solid'
import configuration from '../../config';
import { toast } from 'react-toastify';
import html2pdf from "html2pdf.js";
import DataTable from "../../components/MDataTable";

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// For Edit User
function Edit({ history }) {
  const location = useLocation();
  const Id = location.state;
  const [currency, setCurrency] = useState('')
  const [tripDetail, setTripDetail] = useState({
    passenger_info:{},
    driver_info:{},
    vehicle_ride_info:{},
    service_info:{},
    fare_info:{},
    rental_price_info:{},
    discount:{},
    drop_off:[],
  });
  const columns = [
    {
      title: "Emplayee Name",
      field: "first_name",
      render: (rowData) => (
        <span>
          {rowData.first_name} {rowData.last_name}
        </span>
      ),
    },
    {title: "Email",field: "email"},
    {title: "Mobile",field: "mobile"},
    {
      title: "Pickup Location",
      field: "pick_up",
      render: (rowData) => (
        <span>
          {rowData.pick_up.location}
        </span>
      ),
    },
    {
      title: "Drop Off Location",
      field: "drop_off",
      render: (rowData) => (
        <span>
          {rowData.drop_off.location}
        </span>
      ),
    },
    {title: "Status",field: "status", width: "10%",
      render: (rowData) => {
        let status = (rowData.status === 'drop_off')?'completed':rowData.status;
        status = status.split('_').join(' ');
        return (<span className="capitalize">{status}</span>)},}
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    // var url = window.location.href;
    // Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'trip/detail', params: {trip_id: Id} }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setTripDetail(data.payload)
        setData(data.payload.car_pool_passengers);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setCurrency(data.payload.currency)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);
  function printInvoice() {
      const element = document.getElementById("make_pdf");
      let fileName = tripDetail.trip_id;
      var opt = {
          margin: 0.5,
          filename: fileName + '.pdf',
          image: { type: 'jpeg' },
          html2canvas: { scale: 5 },
          jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
      };
      html2pdf().from(element).set(opt).save();
  }
  return (
    <>
      <Breadcrumb module={labels.TripsDetail} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Trips Details" />
                  </div>
                  <div className="mb-5 text-right">
                    {/*<button type="button" className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                      <span className="sr-only">Open options</span>
                      <PaperAirplaneIcon className="h-5 w-5 inline" aria-hidden="true" /> Send to Customer
                    </button>*/}
                    <button type="button" onClick={printInvoice} className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                      <span className="sr-only">Open options</span>
                      <DownloadIcon className="h-5 w-5 inline" aria-hidden="true" /> Download as PDF
                    </button>
                    {/*<Link to={`/invoice/${tripDetail.trip_id}`}>
                    <button type="button" className="py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                      <span className="sr-only">Open options</span>
                      <EyeIcon className="h-5 w-5 inline" aria-hidden="true" /> Preview Invoice
                    </button></Link>*/}
                  </div>
                  <div id="make_pdf">
                    <section className="grid grid-cols-2 gap-4">
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Passenger Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Name</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.passenger_info.first_name} {tripDetail.passenger_info.last_name}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Email</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.passenger_info.email}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Mobile No</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.passenger_info.mobile_country_code} {tripDetail.passenger_info.mobile}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        {(tripDetail.driver_info)?
                          <>
                            <div className="px-4 py-5 sm:px-6">
                              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Driver Details
                              </h2>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Name</dt>
                                  <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.first_name} {tripDetail.driver_info.last_name}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Email</dt>
                                  <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.email}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                  <dt className="text-basefont-medium text-gray-500">Mobile No</dt>
                                  <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.mobile_country_code} {tripDetail.driver_info.mobile}</dd>
                                </div>
                              </dl>
                            </div>
                          </> : ''
                        }
                        
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Trip Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Trip Id</dt>
                              <dd className="mt-1 text-base text-gray-900">#{tripDetail.trip_id}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Book Date</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.booking_date}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Pickup Address</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.pick_up}</dd>
                            </div>
                            {}
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Dropoff Address</dt>
                              {                
                                tripDetail.drop_off.map((e, key) => {
                                  return(<dd className="mt-1 text-base text-gray-900">{key+1}. {e.location}</dd>)
                                })
                              }
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Distance</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.formatted_total_distance}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Total Duration</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.formatted_total_duration}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Service</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.service_info.code}</dd>
                            </div>
                            {(tripDetail.service_info.code === 'OUT_STATION')?
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Out Station Trip Type</dt>
                                <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.outstation_trip_type}</dd>
                              </div>:''
                            }
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Vehicle</dt>
                              <dd className="mt-1 text-base text-gray-900">{tripDetail.vehicle_ride_info.title}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Payment Type</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.payment_type}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Payment Status</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.payment}</dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-basefont-medium text-gray-500">Trip Status</dt>
                              <dd className="mt-1 text-base text-gray-900 capitalize">{tripDetail.status}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Fare Details
                          </h2>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Min Base Mile</dt>
                                <dd className="mt-1 text-base text-gray-900">{tripDetail.fare_info.min_base_mile}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Min Base Fare</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.min_base_fare).toFixed(2)}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Per KM Fare</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.cost_per_mile).toFixed(2)}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Per Min Fare</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.cost_per_min).toFixed(2)  }</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Wait Fees</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.wait_fees).toFixed(2)}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Base Fare</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.base_fare).toFixed(2)}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Total Mile Fare</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.total_mile_fare).toFixed(2)}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Total Min Fare</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.total_min_fare).toFixed(2)}</dd>
                              </div>
                              <div className="sm:col-span-1">
                                <dt className="text-basefont-medium text-gray-500">Incentives Multiplier</dt>
                                <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.incentives_multiplier).toFixed(2)}</dd>
                              </div>
                            </dl>
                          </div>
                      </div>
                    </section>
                    {(tripDetail.service_info.code ==='COMMUTE')?
                      <div className="bg-white shadow sm:rounded-lg mt-5">
                        <DataTable
                            columns={columns}
                            data={data}
                            title={'Car Pool Passenger List'}
                            disabledCheckbox={true}
                            disabledPagination={true}
                            disabledSearch={true}
                        />
                      </div>:''
                    }
                    {/*<div className="grid grid-cols-2 mt-5 gap-4">
                      <div className="bg-white shadow sm:rounded-lg py-4 px-4">
                        <img src={tripDetail.google_map_image} alt="" />
                      </div>
                      
                    </div>*/}
                    <div className="total">
                      <div className="mt-5 float-right	">
                        <dl style={{ minWidth: '320px', }} className=" space-y-6 pb-5 pt-5">
                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Subtotal</dt>
                            <dd className="text-basefont-medium text-gray-900">{currency} {tripDetail.subtotal}</dd>
                          </div>
                          {(tripDetail.discount)?
                            <div className="flex items-center justify-between">
                              <dt className="text-sm">Discount</dt>
                              <dd className="text-base font-medium text-gray-900">{currency} {Number(tripDetail.discount).toFixed(2)}</dd>
                            </div>:''
                          }
                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Tax</dt>
                            <dd className="text-base font-medium text-gray-900">{currency} {tripDetail.tax}</dd>
                          </div>
                          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                            <dt className="text-base font-medium">Total</dt>
                            <dd className="text-base font-medium text-gray-900">{currency} {tripDetail.total}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit;
