import React from "react";
import labels from "../constants/Footer";

// For Footer
function Footer() {
  return (
    <footer className="w-full px-4 sm:px-6 lg:px-8">
      <div className="py-12 text-center md:flex md:justify-between">
        <p className="text-base text-gray-400">
          &copy; {labels.allRightsReserved}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
