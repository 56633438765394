import {MailIcon, DeviceMobileIcon} from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let Id = "";
// List Settings
function Summary({user_id}) {
    const [actions, setActions] = useState([]);
    useEffect(() => {
        Id = user_id;
        configuration.getAPI({url:'app-user/list-cards', params:{user_id:Id}}).then((data) => {
          if(data.status === 200){
            console.log(data.payload)
            setActions(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
          <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                          Payment Info
                      </h2>
                      <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show user payment info.</p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="grid grid-cols-2 gap-6">
                          {actions.map((single, indx)=>{
                            return (<div className="credit-card visa bg-indigo-600 selectable">
                                <div className="credit">{single.name}</div>
                                <div className="credit-card-last4">{single.last4}</div>                                                                                
                                <div className="credit-card-expiry">{single.exp_year}/{single.exp_year}</div>
                                {/*<TrashIcon className="h-5 w-5 absolute right-2 top-2 cursor-pointer" aria-hidden="true" />*/}
                            </div>)  
                          })}
                          {/*<div className="credit-card visa bg-indigo-600 selectable">
                              <div className="credit">
                              John Kartar
                              </div>
                              <div className="credit-card-last4">
                                  4242
                              </div>                                                                                
                              <div className="credit-card-expiry">
                                  08/25
                              </div>
                          </div>
                          <div className="credit-card mastercard bg-indigo-600 selectable">
                              <div className="credit">
                              John Kartar
                              </div>
                              <div className="credit-card-last4">
                                  4242
                              </div>                                                                                
                              <div className="credit-card-expiry">
                                  08/25
                              </div>
                          </div>*/}
                      </div>
                  </div>
              </div>
          </section>
      </div>
    );
}

export default Summary;
