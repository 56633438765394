import React, { useState, useEffect } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/HelpCategory";
import commonConstant from "../../constants/Common";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Add Help Category
function Add({ history }) {
	const [fields, setFields] = useState({});
	const [languages, setLanguages] = useState([])
	const [errors, setErrors] = useState({});

	const statusOpt = [
		{ id: 1, title: "Active", value: "active" },
		{ id: 2, title: "Inactive", value: "inactive" },
		{ id: 3, title: "Archive", value: "archive" },
	];
	const [fieldArr, setFieldArr] = useState([
		{
			id: 0,
			title: "User Type",
			name: "user_type",
			type: "Dropdown",
			options: commonConstant.user_type,
		},
		{ id: 2, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
	]);

	useEffect(() => {
		configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'help_categories' } }).then((data) => {
			if (data.status === 200) {
				let orderNoArr = []
				UND.each(data.payload, (singleRec) => {
					orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
				})
				const orderNoObj = {
					id: 1,
					title: "Order No",
					name: "order_no",
					type: "Dropdown",
					options: orderNoArr,
				}
				fieldArr.push(orderNoObj)
			}
		}).catch(error => {
			return toast.error(error.message)
		});

		configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
			if (data.status === 200) {
				setLanguages(data.payload)
				let languages = data.payload;
				let fieldArr_data = [];
				languages.map((language, index) => {
					fieldArr_data.push({
						id: 3 + index,
						title: `Title [${language.title}]`,
						name: `title_${language.code}`,
						type: "TextInput"
					});
				})
				setFieldArr([...fieldArr, ...fieldArr_data])
			}
		}).catch(error => {
			return toast.error(error.message)
		});
	}, []);



	function handleValidation() {
		let flag = true;
		let error = {}
		if (!fields.user_type) {
			error['user_type'] = "Please select user type"
			flag = false
		}
		if (!fields.order_no) {
			error['order_no'] = "Please enter order no"
			flag = false
		}
		if (!fields.status) {
			error['status'] = "Please select status"
			flag = false
		}
		UND.each(languages, (lang, ind) => {
			if (!fields['title_' + lang.code]) {
				error['title_' + lang.code] = "Please enter title in " + lang.title
				flag = false
			}
		})
		setErrors({ ...error })
		return flag;
	}

	function handleChange(field, e) {
		setFields({ ...fields, [field]: e.target.value })
	}

	const handleSubmit = () => {
		if (handleValidation()) {
			configuration.postAPI({ url: 'help-category/create', params: fields }).then((data) => {
				if (data.status === 200) {
					history.push('/help-category')
				} else if (data.status === 404) {
					return toast.error(data.error.message)
				} else {
					return toast.error('Something went wrong')
				}
			}).catch(error => {
				return toast.error(error.message)
			});
		}
	}

	return (
		<>
			<Breadcrumb module={labels.addHelpCategory} />
			<AddEditSection
				title="Add Help Category"
				path="/help-category"
				fields={fieldArr}
				fieldError={errors}
				fieldValue={fields}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
			/>
		</>
	);
}

export default Add;