import PropTypes from "prop-types";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// For Input Element
function TextInput({ id, name, handleChange, value}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker 
          className="shadow-sm focus:ring-indigo-500 py-3 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
          name={name}
          id={id}
          value={value} 
          format="MM/dd/yyyy"
          onChange={(e)=>handleChange(name, e)} 
          showTodayButton
          disablePast
        />
    </MuiPickersUtilsProvider>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: "",
  name: "",
};

export default TextInput;
