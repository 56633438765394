import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../constants/VehicleYear";
import configuration from '../../config';
import { toast } from 'react-toastify';

// For Edit State
let Id = "";
function Edit({history}) {
  let [makeList, setMakeList] = useState([]);
  let [modelList, setModelList] = useState([]);
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const fieldArr = [
    {
      id: 0,
      title: "Vehicle Maker",
      name: "vehicle_make_id",
      type: "Dropdown",
      options: makeList,
    },
    {
      id: 1,
      title: "Vehicle Model",
      name: "vehicle_model_id",
      type: "Dropdown",
      options: modelList,
    },

    { id: 2, title: "Title", name: "title", type: "TextInput", is_number: true },
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please Enter Title"
      flag = false
    }

    if (!fields.vehicle_make_id) {
      error['vehicle_make_id'] = "Please Select Vehicle Maker"
      flag = false
    }
    if (!fields.vehicle_model_id) {
      error['vehicle_model_id'] = "Please Select Vehicle Model"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'year/update', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/vehicleyear')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  
  function getMake(){
    configuration.getAPI({ url: 'make/list', params: { status: "active" } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.vehicle_make_id;
            element.id = element.vehicle_make_id;
            return element;
          });
        }
        setMakeList(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function getModel(Id){
    setFields({ ...fields, vehicle_model_id: '' })
    configuration.getAPI({ url: 'model/list', params: { status: "active", vehicle_make_id:Id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.title = element.title;
            element.value = element.vehicle_model_id;
            element.id = element.vehicle_model_id;
            return element;
          });
        }
        setModelList(data.payload)
      }
    })
  }
  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'year/list', params:{vehicle_year_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        getModel(data.payload.vehicle_make_id);
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    getMake();
    // console.log(configuration.baseURL);
  }, []);
  function handleChange(field, e) {
    if(field == 'vehicle_make_id'){
      getModel(e.target.value);
    }
    setFields({ ...fields, [field]: e.target.value })
  }
  return (
    <>
      <Breadcrumb module={labels.editVehicleYear} />
      <AddEditSection title="Edit Vehicle Year" path="/vehicleyear" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
