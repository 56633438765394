import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/Form/Dropdown";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { TrashIcon, PencilIcon} from "@heroicons/react/outline";
import { Link } from "react-router-dom";

import configuration from '../../../config';
import { toast } from 'react-toastify';
import { findWhere } from 'underscore';

let Id = "";
// List Settings
function Summary({user_id}) {
    let [editFlag, setEditFlag] = useState(false);
    let [makeList, setMakeList] = useState([]);
    let [modelList, setModelList] = useState([]);
    let [yearList, setYearList] = useState([]);
    let [rideOptionList, setRideOptionList] = useState([]);
    const vehicles = [
        { id: 0, title: "Car Type", name: "vehicle_ride_option_id", type: "Dropdown", options: rideOptionList },
        { id: 1, title: "Car Make", name: "vehicle_make_id", type: "Dropdown", options: makeList },
        { id: 4, title: "Modal", name: "vehicle_model_id", type: "Dropdown", options: modelList },
        { id: 5, title: "Year", name: "vehicle_year_id", type: "Dropdown", options: yearList },
        { id: 6, title: "Capacity", name: "capacity", type: "TextInput", is_number:true },
        { id: 6, title: "Plate Number", name: "vehicle_plate_number", type: "TextInput" },
        { id: 8, title: "Upload Picture of Car", name: "photo", type: "FileInput" },
    ];
    const [fields, setFields] = useState({});
    const [photo, setPhoto] = useState(null);
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState({
    });
  function getModel(Id){
    setFields({ ...fields, vehicle_model_id: '', vehicle_year_id:'', })
    setYearList([])
    configuration.getAPI({ url: 'model/list', params: { status: "active", vehicle_make_id:Id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.value = element.vehicle_model_id;
            element.id = element.vehicle_model_id;
            return element;
          });
        }
        setModelList(data.payload)
      }
    })
  }

  /*function getYear(Id) {
    setFields({ ...fields, vehicle_year_id: '' });
    configuration.getAPI({ url: 'year/list', params: { status: "active", vehicle_model_id: Id } }).then((data) => {
      if (data.status === 200) {
        console.log("Raw year list: " + JSON.stringify(data.payload, null, 2));
        setYearList(data.payload);
      }
    });
  }
  
  function getYear_2(Id) {
    setFields({ ...fields, vehicle_year_id: '' });
    configuration.getAPI({ url: 'year/list', params: { status: "active", vehicle_model_id: Id } }).then(async (data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          // Use Promise.all to wait for all asynchronous operations to complete
          const processedData = await Promise.all(data.payload.map(async (element) => {
           // element.title = element.title;
            element.value = element.vehicle_year_id;
            element.id = element.vehicle_year_id;
            return element;
          }));
          console.log("year list: " + JSON.stringify(processedData, null, 2));
          setYearList(processedData);
        }
      }
    });
  }
  */
  function getYear(Id){
   
    setFields({ ...fields, vehicle_year_id: '' })
    configuration.getAPI({ url: 'year/list', params: { status: "active", vehicle_model_id:Id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload.length > 0) {
          data.payload.forEach((element) => {
            element.value = element.vehicle_year_id;
            element.id = element.vehicle_year_id;
            return element;
          });
        }
        console.log("year list: " + JSON.stringify(data.payload, null, 2));
        setYearList(data.payload)
      }
    })
  }
  function getVehicle(Id){

   // alert(Id);
    configuration.getAPI({ url: 'app-user/list-vehicle', params: { user_id:Id } }).then((data) => {
      if (data.status === 200) {
        
        setProducts(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
    useEffect(() => {
        // console.log(props.location)
        Id = user_id;
        configuration.getAPI({ url: 'ride-option/list', params: { status: "active" } }).then((data) => {
          if (data.status === 200) {
            if (data.payload.length > 0) {
              data.payload.forEach((element) => {
                element.value = element.vehicle_ride_option_id;
                element.id = element.vehicle_ride_option_id;
                return element;
              });
            }
            setRideOptionList(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        configuration.getAPI({ url: 'make/list', params: { status: "active" } }).then((data) => {
          if (data.status === 200) {
            if (data.payload.length > 0) {
              data.payload.forEach((element) => {
                element.value = element.vehicle_make_id;
                element.id = element.vehicle_make_id;
                return element;
              });
            }
            setMakeList(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        getVehicle(Id);
        // console.log(configuration.baseURL);
      }, []);
    function handleChange(field, e) {
        if(field === 'photo'){
          setPhoto(e.target.files[0]);
          setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
        } else {
            if(field === 'vehicle_make_id'){
              getModel(e.target.value);
            }
            if(field === 'vehicle_model_id'){
              getYear(e.target.value);
            }
            setFields({ ...fields, [field]: e.target.value })
        }
    }
    function cancleImage(field, e) {
        setPhoto(null);
        setFields({ ...fields, [field]: '' })
    }

    function handleValidation() {
        let flag = true;
        let error = {}

        if (!fields.photo) {
          error['photo'] = "Please Select photo"
          flag = false
        }
        if (!fields.vehicle_ride_option_id) {
          error['vehicle_ride_option_id'] = "Please Select ride option"
          flag = false
        }
        if (!fields.vehicle_model_id) {
          error['vehicle_model_id'] = "Please Select model"
          flag = false
        }
        if (!fields.vehicle_make_id) {
          error['vehicle_make_id'] = "Please Select make"
          flag = false
        }
        if (!fields.vehicle_year_id) {
          error['vehicle_year_id'] = "Please Select year"
          flag = false
        }
        
        if (!fields.capacity) {
          error['capacity'] = "Please enter capacity"
          flag = false
        }
        if (!fields.vehicle_plate_number) {
          error['vehicle_plate_number'] = "Please enter plate number"
          flag = false
        }
        setErrors({ ...error })
        return flag;
    }

    const handleSubmit = () =>{
        if (handleValidation()) {
          const formData = new FormData();
          if(photo){
            formData.append('photo', photo);
          }
          formData.append('fields', JSON.stringify({...fields, lang_code:'EN', user_id:Id}));
          const apiURL = editFlag?'app-user/edit-vehicle':'app-user/add-vehicle';

          configuration.postFormDataAPI({url:apiURL, params:formData}).then((data) => {
            if(data.status === 200){
                setPhoto(null);
                setFields({
                    vehicle_ride_option_id:'',
                    vehicle_model_id:'',
                    vehicle_make_id:'',
                    vehicle_year_id:'',
                    vehicle_plate_number:'',
                    capacity:'',
                    photo:'',
                })
                setEditFlag(false)
                getVehicle(Id);
              return toast.success((editFlag)?"Vehicle updated successfully":"Vehicle added successfully");
            } else if (data.status === 404) {
              return toast.error(data.error.message)
            } else {
              return toast.error('Something went wrong')
            }
          }).catch(error => {
            return toast.error(error.message)
          });
        }
    }
    function handleDelete(vehicle_id) {
        configuration
        .postAPI({ url: "app-user/remove-vehicle", params: {user_id:Id, vehicle_id} })
        .then((data) => {
          if (data.status === 200) {
            getVehicle(Id);
            return toast.error("Vehicle deleted successfully");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
    function handleEdit(vehicle_id){
        let vehicle = findWhere(products, {vehicle_id});
        console.log(vehicle)
        setEditFlag(true)
        getModel(vehicle.vehicle_make_id);
        getYear(vehicle.vehicle_model_id);
        setFields(vehicle)
    }
    function makeDefault(vehicle_id){
      console.log(vehicle_id)
      configuration
      .postAPI({ url: "app-user/default-vehicle", params: {user_id:Id, vehicle_id} })
      .then((data) => {
        if (data.status === 200) {
          getVehicle(Id);
          return toast.success("Vehicle make default successfully");
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    }
    function handleClear(){
        setPhoto(null);
        setEditFlag(false)
        setFields({
            vehicle_ride_option_id:'',
            vehicle_model_id:'',
            vehicle_make_id:'',
            vehicle_year_id:'',
            vehicle_plate_number:'',
            capacity:'',
            photo:'',
        })
        getVehicle(Id);
    }
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Vehicle Info
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
  Vehicle
</h2>

{products.length > 0 ? (
  <ul role="list" className="grid grid-cols-2 gap-6">
    {products.map((product) => (
      <li key={product.vehicle_id} className={(product.is_default) ? "flex py-6 sm:py-6 px-4 shadow-lg rounded-md bg-indigo-50 border-indigo-200" : "flex py-6 sm:py-6 px-4 shadow-lg rounded-md"}>
        <div className="flex-shrink-0">
          <img
            src={product.photo}
            alt={product.vehicle_id}
            className="w-16 h-16 rounded-md object-center object-cover sm:w-20 sm:h-20"
          />
        </div>

        <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
          <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
            <div>
              <div className="flex justify-between">
                <h3 className="text-base">
                  <p className="font-semibold text-gray-700 hover:text-gray-800">
                    {product.type}
                  </p>
                </h3>
              </div>
              <p className="mt-1 text-sm font-medium text-gray-900">{product.make}</p>
              <div className="mt-1 flex text-sm">
                <p className="text-gray-500">{product.model} {product.year}</p>
              </div>
            </div>

            <div className="mt-4 sm:mt-1">
              <div className="absolute top-0 right-12">
                <button type="button" onClick={(e) => handleEdit(product.vehicle_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5 " aria-hidden="true" />
                </button>
              </div>
              {!product.is_default ? <div className="text-right pt-10"><span onClick={() => makeDefault(product.vehicle_id)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">Make Default</span></div> : ''}
            </div>
            <div className="mt-4 sm:mt-0">
              <div className="absolute top-0 right-0">
                <button type="button" onClick={(e) => handleDelete(product.vehicle_id)} className="-m-2 p-2 bg-indigo-600 inline-flex text-white rounded-md">
                  <span className="sr-only">Remove</span>
                  <TrashIcon className="h-5 w-5 " aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    ))}
  </ul>
) : (
  <p>No vehicles available for this driver</p>
)}
<br/> <br/>
                        <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                            Add Vehicle
                        </h2>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            {vehicles.map((field) => {
                                return (
                                    <div className="sm:col-span-3" key={field.id}>
                                        <Label title={field.title} />
                                        <div className="mt-1">
                                            {field.type === "TextInput" ? (
                                                <TextInput
                                                    id={field.name}
                                                    name={field.name}
                                                    value={fields[field.name]}
                                                    handleChange={handleChange}
                                                    is_number={field.is_number || false}
                                                    is_password={field.is_password || false}
                                                />
                                            ) : field.type === "Dropdown" ? (
                                                <Dropdown
                                                    id={field.name}
                                                    value={fields[field.name]}
                                                    name={field.name}
                                                    handleChange={handleChange}
                                                    status={field.options || []}
                                                />
                                            ) : field.type === "FileInput" ? (
                                                <FileInput
                                                    id={field.name}
                                                    value={fields[field.name]}
                                                    handleChange={handleChange}
                                                    cancleImage={cancleImage}
                                                    name={field.name}
                                                />
                                            ) : null}
                                        </div>
                                        {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="pt-5 border_color_theme">
                            <div className="flex justify-end">
                                <button
                                  type="button"
                                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                  onClick={() => handleClear()}
                                >
                                  Clear
                                </button>
                                <BtnSaveRecord title={(editFlag)?"Update":"Save"} handleSubmit={handleSubmit} path="/driverprofile" />
                            </div>
                        </div>
                        
                      

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
