import React, { useState, useEffect } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/VehicleModel";
import { toast } from 'react-toastify';
import configuration from '../../config';

function View({ location }) {
  const columns = [
    { title: "Make", field: "make" },
    { title: "Year", field: "year" },
    { title: "Model", field: "model" },
  ];

  const btnGroups = [
    { id: 1, title: "View Models", type: "addNew", path: "/" }
  ];

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [importedData, setImportedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
  
    if (!uploadedFile) {
      toast.error("Please select a file to upload");
      return;
    }
  
    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvData = event.target.result;
      //
      try {
        const csvDataFromFile = await readFileAsync(uploadedFile);
        console.log("csvDataFromFile:", csvDataFromFile);
  
        const parsedData = await parseCSV(csvData);
        console.log("parsedData:", parsedData);
  
        setData(parsedData);
        toast.success("Data Loaded from File");
       
      } catch (error) {
        toast.error("Error parsing CSV file");
      }
    };
  
    reader.readAsText(uploadedFile);
  };
  
   const handleImport = () => {

    configuration.postAPI({ url: "import/vehicles", params: {vehicles: data} }).then((data) => {
         
      toast.success(data.status);
       if (data.status === 200) {
           
            toast.success("Data imported successfully");

          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        }); 
      
    


    data.forEach((item, index) => {
      const { year, make, model } = item;
  
      // Do something with each property
      console.log(`Item ${index + 1}:`);
      console.log(`Year: ${year}`);
      console.log(`Make: ${make}`);
      console.log(`Model: ${model}`);

    
  
      // You can perform any specific action with each property here
    });
  
    
  };
// Ensure that the file input is properly handling the change event
const handleFileInputChange = (e) => {
  // Trigger the file upload function
  handleFileUpload(e);
};
  //const handl = async () => {
   
  //  alert('upload to database');
    //first model, make, year.
    //get a list models,

    const handleDeleteAllRecords = () => {
      if (window.confirm("Are you sure you want to delete all records?")) {
        configuration
          .postAPI({ url: "/import/delete-all" }) // Change the endpoint as per your server implementation
          .then((data) => {
            if (data.status === 200) {
              toast.success("All records deleted successfully");
              setData([]); // Clear the data in the state after successful deletion
            } else if (data.error) {
              toast.error(data.error.message);
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
    };
  const parseCSV = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const parsedData = [];
  
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length === headers.length) {
        const rowData = {};
        for (let j = 0; j < headers.length; j++) {
          const header = headers[j].trim();
          const value = currentLine[j].trim();
          if (header !== 'body_styles') {
            rowData[header] = value;
          }
        }
        parsedData.push(rowData);
      }
    }
  
    return parsedData;
  };
  
  
  


  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  useEffect(() => {
    // Perform any necessary data fetching or initialization
  }, [data]);

  return (
    <>
      {/* ... (existing code) */}

     
      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <input type="file" id="file" onChange={handleFileInputChange} />
        <button type="button" onClick={handleImport} disabled={isLoading}>
          {isLoading ? "Uploading..." : "Upload"}
        </button>
        <button type="button" onClick={handleDeleteAllRecords} style={{ marginLeft: "10px" }}>
          Delete All Records
        </button>
      </div>
      {/* Use key prop to force re-render of ListSection when data changes */}
      <ListSection
       
        captionTitle="Import Vehicle"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
      />

    </>
  );
}

export default View;
