import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Tax";

// Edit Tax
let Id = "";

function Edit({ history }) {
  const statusOpt = [
    
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const typeOpt = [
    { id: 1, title: "Percentage", value: "percentage" },
    { id: 2, title: "Amount", value: "amount" },
  ];

  const fieldArr = [
    { id: 0, title: "Title", name: "name", type: "TextInput" },
    { id: 1, title: "Type", name: "type", type: "Dropdown", options: typeOpt },
    { id: 2, title: "Value", name: "value", type: "TextInput" },
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    type: "",
    value: "",
    status: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please Enter Name"
      flag = false
    }

    if (!fields.value) {
      error['value'] = "Please Enter Value "
      flag = false
    }
    if (!fields.type) {
      error['type'] = "Please Select Type"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "tax/list", params: { tax_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "tax/update", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/tax");
            // setData(data.payload)
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editTax} />
      <AddEditSection
        title="Edit Tax"
        path="/tax"
        fields={fieldArr}
        fieldValue={fields}
        handleChange={handleChange}
        fieldError={errors}

        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
