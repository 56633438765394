import PropTypes from "prop-types";
import React, {useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Label from "../../components/Form/Label";
import labels from "../../constants/OTP";
// import common from "../../constants/Common";
import configuration from '../../config';
import { toast } from 'react-toastify';
import {reactLocalStorage} from 'reactjs-localstorage';

var jwt = require('jsonwebtoken');

function View({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState('');
  function generateToken(user) {
    var object = {
        email: user.email,
        name:user.name,
        user_id:user.user_id,
        photo:user.photo
    };
    // console.log(object)
    return jwt.sign(object, configuration.appName, {
        expiresIn: '1d'
    });
  }
  useEffect(() => {
    var token = reactLocalStorage.get('token');
    try {
        jwt.verify(token, configuration.appName, function (err, decoded){
            if (err){
                decoded = null;
                history.push("/otp")
              }
              else{
                history.push("/dashboard")
              }
            });
    } catch(err) {
        history.push("/otp")
    }
    var email = reactLocalStorage.get('email');
    if(!email){
      history.push("/")
    }
    setFields({...fields, email})
    // console.log(email)
  }, []);

  const handleSubmit = () =>{
    // console.log("submit")
    const otp1 = document.getElementById('si_otp1').value
    const otp2 = document.getElementById('si_otp2').value
    const otp3 = document.getElementById('si_otp3').value
    const otp4 = document.getElementById('si_otp4').value
    const otp5 = document.getElementById('si_otp5').value
    const otp6 = document.getElementById('si_otp6').value
    const otp = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    // console.log(otp)
    if (otp && otp !== '' && otp !== 0 && otp.length === 6) {
      setErrors({otp:""});
      configuration.postAPI({url:'auth/verify-otp', params:{...fields, otp}}).then((data) => {
        if(data.status === 200){
          var token = generateToken(data.payload);
          reactLocalStorage.set('token', token);
          reactLocalStorage.set('loginlog_id', data.payload.loginlog_id);
          reactLocalStorage.set('user_id', data.payload.user_id);
          history.push("/dashboard")
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    } else {
      setErrors({otp:"Enter 6 digit OTP"});
    }
  }
  const checkValidOtp = (e, type) => {
    const otp_type = type.slice(0, -1);
    const re = /^[0-9\b]+$/;
    let value = e.target.value
    if (value === '' || re.test(value)) {
      if (value !== '') {
        var nextInput = parseInt(type.charAt(type.length - 1)) + 1;
        if (nextInput <= 6 && otp_type === 'si_otp') {
          document.getElementById('si_otp' + nextInput).focus();
        }
      } else {
        var preInput = parseInt(type.charAt(type.length - 1)) - 1;
        if (preInput <= 6 && preInput >= 1 && otp_type === 'si_otp') {
          document.getElementById('si_otp' + preInput).focus();
        }
      }
    } else {
      document.getElementById(type).value = '';
      // console.log('fails')
    }
  }
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 page_bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="../image/logo.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 text_theme">
          {labels.signInToYourAccount}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 box_bg">
          <form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700 text-base text_theme"
              >
                {labels.emailAddress}{" "}
              </label>
              <div class="mt-9">
                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="mt-1">
                    <input type="text" name="city" id="si_otp1" onChange={e => checkValidOtp(e, 'si_otp1')} maxLength="1"  class=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md text-center" />
                  </div>

                  <div class="mt-1">
                    <input type="text" name="city" id="si_otp2" onChange={e => checkValidOtp(e, 'si_otp2')} maxLength="1"  class=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md text-center" />
                  </div>

                  <div class="mt-1">
                    <input type="text" name="city" id="si_otp3" onChange={e => checkValidOtp(e, 'si_otp3')} maxLength="1"  class=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md text-center" />
                  </div>

                  <div class="mt-1">
                    <input type="text" name="city" id="si_otp4" onChange={e => checkValidOtp(e, 'si_otp4')} maxLength="1"  class=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md text-center" />
                  </div>

                  <div class="mt-1">
                    <input type="text" name="city" id="si_otp5" onChange={e => checkValidOtp(e, 'si_otp5')} maxLength="1"  class=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md text-center" />
                  </div>

                  <div class="mt-1">
                    <input type="text" name="city" id="si_otp6" onChange={e => checkValidOtp(e, 'si_otp6')} maxLength="1"  class=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-baseborder-gray-300 rounded-md text-center" />
                  </div>
                </div>
              </div>
              {errors.otp ? <Label title='otp' fieldError={errors.otp} /> : null}
            </div>
            <div>
              <button
                type="button"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSubmit}
              >
                {labels.signIn}
              </button>
            </div>
          </form>
          <div className="text-center mt-6 text-base text_theme">
            {labels.backTo}{" "}
            <Link className="text-indigo-600 hover:text-indigo-500" to="/">
              {labels.signIn}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  history: PropTypes.func,
  // "history.push": PropTypes.func,
};

View.defaultProps = {
  history: () => {
     // do nothing.
  },
  // "history.push": () => {
  //    // do nothing.
  // },
};

export default View;
