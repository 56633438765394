import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/Trip";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    {
      title: "ID",
      field: "report_problem_id",
    },
    {
      title: "Trip ID",
      field: "trip_id",
    },
    { title: "Email ", field: "email" },
    { title: "Problem ", field: "problem" },
    {
      title: "Media",
      field: "image",
      render: (rowData) => (
        <img name={rowData.image} id={rowData.image} src={rowData.image} width="100" height="100"/>
      ),
    },
    { title: "Date", field: "created_at" },
  ];
  const location = useLocation();
  const Id = location.state;
  const btnGroups = [];
  const [data, setData] = useState([]);
  
  useEffect(() => {
    configuration.getAPI({url:'trip/list-report', params:{trip_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.TripReports} />
      <ListSection
        captionTitle="Trips Reports"
        columns={columns}
        data={data}
        disabledCheckbox={true}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
