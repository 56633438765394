import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Currency";

// For Edit Currency
let Id = "";
function Edit({ history }) {
  const statusOpt = [

    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const fieldArr = [
    { id: 1, title: "Title", name: "name", type: "TextInput" },
    { id: 0, title: "Code", name: "code", type: "TextInput", isDisable: true },
    { id: 2, title: "Symbol", name: "symbol", type: "TextInput", isDisable: true },
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    code: "",
    symbol: "",
    status: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please Enter Name"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please Enter Code"
      flag = false
    }
    if (!fields.symbol) {
      error['symbol'] = "Please Enter Symbol"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "currency/list", params: { currency_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "currency/update", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/currency");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editCurrency} />
      <AddEditSection
        title="Edit Currency"
        path="/currency"
        fields={fieldArr}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        fieldError={errors}

      />
    </>
  );
}

export default Edit;
