import React, { useState, useEffect } from "react";
import { TrashIcon} from "@heroicons/react/outline";
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import FileInput from "../../../components/Form/FileInput";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import configuration from '../../../config';
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary({user_id}) {
    const fieldArr2 = [
        
        { id: 2, title: "Driving Licence Front Picture", name: "driving_license_front_picture", type: "FileInput" },
        { id: 3, title: "Driving Licence Back Picture", name: "driving_license_back_picture", type: "FileInput" },
        { id: 1, title: "Driving Licence Number", name: "driving_license_number", type: "TextInput" },
        
    ];
    const [fields, setFields] = useState({});
    const [driving_license_front_picture, setLicenseFront] = useState(null);
    const [driving_license_back_picture, setLicenseBack] = useState(null);
    const [errors, setErrors] = useState({
        driving_license_front_picture: "",
        driving_license_back_picture: "",
        driving_license_number: "",
    });
    useEffect(() => {
        Id = user_id;
        
        configuration.getAPI({url:'app-user/licence-detail', params:{user_id:Id}}).then((data) => {
          // console.log(data)
          if(data.status === 200 && data.payload){
            setFields(data.payload);
          }
        }).catch(error => {
          // console.log(error)
          return toast.error(error.message)
        });
    }, []);

    function handleChange(field, e) {
        if(field === 'driving_license_front_picture'){
            setLicenseFront(e.target.files[0]);
            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
        } else if(field === 'driving_license_back_picture'){
            setLicenseBack(e.target.files[0]);
            setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
        } else {
            setFields({ ...fields, [field]: e.target.value })
        }
    }
    function cancleImage(field, e) {
        if(field === 'driving_license_front_picture'){
            setLicenseFront(null);
        } else{
            setLicenseBack(null);
        }
        setFields({ ...fields, [field]: '' })
    }
    function validation() {
        let flag = true;
        let error = {}
        if (!fields.driving_license_front_picture) {
          error['driving_license_front_picture'] = "Please select licence front"
          flag = false
        }

        if (!fields.driving_license_back_picture) {
          error['driving_license_back_picture'] = "Please select licence back"
          flag = false
        }

        if (!fields.driving_license_number) {
          error['driving_license_number'] = "Please enter licence number"
          flag = false
        }
        
        setErrors({...error })
        return flag;
    }
    const handleSubmit = () =>{
        if (validation()) {
            console.log(fields)
            const formData = new FormData();
            if(driving_license_front_picture){
                formData.append('driving_license_front_picture', driving_license_front_picture);
            }
            if(driving_license_back_picture){
                formData.append('driving_license_back_picture', driving_license_back_picture);
            }
            formData.append('fields', JSON.stringify({...fields, lang_code:'EN', user_id:Id}));
            configuration.postFormDataAPI({url:'app-user/update-licence', params:formData}).then((data) => {
                if(data.status === 200){
                  // history.push('/driver')
                  return toast.success('Update successfully')
                } else if (data.status === 404) {
                  return toast.error(data.error.message)
                } else {
                  return toast.error('Something went wrong')
                }
            }).catch(error => {
                return toast.error(error.message)
            });
        }
    }
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
        <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        Licence Info 
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="mb-3 text-lg leading-6 font-medium text-gray-900">
                        Add Licence
                    </h2>
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {fieldArr2.map((field) => {
                            return (
                                <div className="sm:col-span-3" key={field.id}>
                                    <Label title={field.title} />
                                    <div className="mt-1">
                                        {field.type === "TextInput" ? (
                                            <TextInput
                                                id={field.name}
                                                name={field.name}
                                                handleChange={handleChange}
                                                value={fields[field.name]}
                                                is_number={field.is_number || false}
                                                is_password={field.is_password || false}
                                            />
                                        ) : field.type === "FileInput" ? (
                                            <FileInput
                                                id={field.name}
                                                name={field.name}
                                                value={fields[field.name]}
                                                handleChange={handleChange}
                                                cancleImage={cancleImage}
                                            />
                                        ) : null}
                                    </div>
                                    {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                </div>
                            );
                        })}
                    </div>
                    <div className="pt-5 border_color_theme">
                        <div className="flex justify-end">
                            <BtnSaveRecord handleSubmit={handleSubmit} title="Save" path="/driverprofile" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
}

export default Summary;
