import {
  AnnotationIcon,
  BellIcon,
  BookmarkAltIcon,
  ChartBarIcon,
  CogIcon,
  LogoutIcon,
  KeyIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  DotsVerticalIcon,
  GlobeAltIcon,
  MenuIcon,
  ShieldCheckIcon,
  TrashIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Dialog, Menu, Popover, Transition } from "@headlessui/react";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import {reactLocalStorage} from 'reactjs-localstorage';
import configuration from '../config';
var jwt = require('jsonwebtoken');

// Multiple classes
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// const user = {
//   name: "Tom Cook",
//   email: "tom@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// };
const solutions = [
  {
    name: "Analytics",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: ChartBarIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: CursorClickIcon,
  },
  {
    name: "Security",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ShieldCheckIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: ViewGridIcon,
  },
];
const resources = [
  { name: "Community", href: "#", icon: UserGroupIcon },
  { name: "Partners", href: "#", icon: GlobeAltIcon },
  { name: "Guides", href: "#", icon: BookmarkAltIcon },
  { name: "Webinars", href: "#", icon: DesktopComputerIcon },
];
const team = [
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "offline",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
  {
    name: "What is Lorem Ipsum",
    handle: "Lorem Ipsum is simply dummy text of the printing and typesetting",
    href: "#",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    status: "online",
  },
];

let user_id = ""
// App header
function Header() {
  const [open1, setOpen1] = useState(false);
  const [user, setUser] = useState({});
  const [activeTab, setActiveTab] = useState("dashboard");
  const history = useHistory();
  function handleSignOut(){
    configuration.postAPI({url:'auth/sign-out', params:{loginlog_id:reactLocalStorage.get('loginlog_id')}}).then((data) => {
      // console.log(data)
      reactLocalStorage.set('token', "");
      reactLocalStorage.set('loginlog_id', "");
      reactLocalStorage.set('user_id', "");
      history.push("/")
      // if(data.status === 200){
      //   // setData(data.payload)
      // } else if (data.error) {
      //   return toast.error(data.error.message)
      // } else {
      //   return toast.error('Something went wrong')
      // }
    }).catch(error => {
      // console.log(error)
      // return toast.error(error.message)
    });
  }
  useEffect(() => {
    var token = reactLocalStorage.get('token');
    jwt.verify(token, configuration.appName , function (err, decoded){
      // console.log(decoded)
        if (err){
            decoded = null;
            history.push("/")
        }
        if(decoded){
          // console.log(decoded)
          user_id = decoded.user_id; 
          setUser({name: decoded.name, email: decoded.email, imageUrl: (decoded.photo !== "")?decoded.photo:"https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"})
        }
    });
    let text = window.location.href;
    if(text.includes("passenger")){
      setActiveTab("passenger")
    } else if(text.includes("app-user")){
      setActiveTab("app-user")
    } else if(text.includes("business")){
      setActiveTab("businessTrip")
    } else if(text.includes("setting")){
      setActiveTab("setting")
    } else if(text.includes("trip")){
      setActiveTab("trip")
    } else if(text.includes("dashboard")){
      setActiveTab("dashboard")
    } else {
      setActiveTab("")
    }
  }, []);
  return (
    <>
      <Popover className="relative bg-white shadow-sm bg-gray-900 header_bg">
        {({ open }) => (
          <>
            <div className="flex justify-between items-center px-4 py-1 sm:px-6 md:justify-start md:space-x-10">
              <div>
                <Link to="/dashboard" onClick={()=>setActiveTab('dashboard')} className="flex">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src="/image/logo-green.png"
                    alt=""
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-green hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                <Popover.Group
                  as="nav"
                  className="flex space-x-10 xl-menu-style"
                >
                  <Link
                    to="/dashboard"
                    onClick={()=>setActiveTab('dashboard')}
                    className={(activeTab === 'dashboard')?"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}>
                    Dashboard
                  </Link>
                  {/* <Link
                    to="/passenger"
                    onClick={()=>setActiveTab('passenger')}
                    className={(activeTab === 'passenger')?"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                    // className="text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700"
                  >
                    Passenger
                  </Link> */}
                  <Link
                    to="/app-user"
                    onClick={()=>setActiveTab('app-user')}
                    className={(activeTab === 'app-user')?"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                    // className="text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"
                  >
                     Users 
                  </Link>
                  <Link
                    to="/trips"
                    onClick={()=>setActiveTab('trip')}
                    className={(activeTab === 'trip')?"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                    // className="text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"
                  >
                    Trips  
                  </Link>
                 
                  <Link
                    to="/setting"
                    onClick={()=>setActiveTab('setting')}
                    className={(activeTab === 'setting')?"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                    // className="text-base font-semibold text-green hover:text-green hover:bg-gray-700 px-3 py-2 rounded-md text_theme"
                  >
                    Settings
                  </Link>
                </Popover.Group>
                {/* <div
                  className="ml-auto bg-gray-700 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen1(true)}
                  onKeyDown={() => setOpen1(true)}
                  role="button"
                  tabIndex="-1"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </div> */}
                {/*<div
                  className="bg-gray-700 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:ml-5"
                >
                  <span className="sr-only">View settings</span>
                  <Link to="/setting">
                    <CogIcon className="h-6 w-6" aria-hidden="true" />
                  </Link>
                </div>*/}
                <Popover className="relative">
                  {() => (
                    <>
                      <Popover.Button className="group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none  text-gray-900">
                        <div className="flex items-center md:ml-7 mt-1">
                          <div className="flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={user.imageUrl}
                              alt=""
                            />
                          </div>
                        </div>
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 right-0 transform  mt-3 px-2 w-max	 sm:px-0">
                          <div className="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-2 sm:p-4">
                              <Link
                                to={`/user/edit/${user_id}`}
                                onClick={()=>setActiveTab('')}
                                className="text-justify text-base font-medium text-indigo-700 hover:text-gray-900"
                              >
                                <CogIcon className="h-6 w-6 inline mr-2" aria-hidden="true" />My Settings
                              </Link>
                              {/* <Link
                                to={`/setting`}
                                onClick={()=>setActiveTab('')}
                                className="text-justify text-base font-medium text-indigo-700 hover:text-gray-900"
                              >
                                <KeyIcon className="h-6 w-6 inline mr-2" aria-hidden="true" />Adminland
                              </Link> */}
                              <button
                                type="button"
                                onClick={handleSignOut}
                                className="text-justify focus:outline-none text-base font-medium text-indigo-700 hover:text-gray-900"
                              >
                                <LogoutIcon className="h-6 w-6 inline mr-2" aria-hidden="true" />Sign Out
                              </button>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="../image/logo.png"
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-6">
                        {solutions.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                          >
                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-green">
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5">
                    <div className="grid grid-cols-2 gap-4">
                      <Link
                        to="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Pricing
                      </Link>

                      <Link
                        to="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Docs
                      </Link>

                      <Link
                        to="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Enterprise
                      </Link>
                      {resources.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div className="mt-6">
                      <Link
                        to="#"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-green bg-indigo-600 hover:bg-indigo-700"
                      >
                        Sign up
                      </Link>
                      <p className="mt-6 text-center text-base font-medium text-gray-500">
                        Existing customer?{" "}
                        <Link
                          to="#"
                          className="text-indigo-600 hover:text-indigo-500"
                        >
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={open1}
          onClose={setOpen1}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll box_bg">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900 text_theme">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 icon_bg"
                            onClick={() => setOpen1(false)}
                            onKeyDown={() => setOpen1(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {team.map((person) => (
                        <li key={person.handle}>
                          <div className="relative group py-6 px-5 flex items-center">
                            <Link
                              to={person.href}
                              className="flex-1 block p-1 w-full"
                            >
                              <div
                                className="absolute inset-0 group-hover:bg-gray-50"
                                aria-hidden="true"
                              />
                              <div className="flex-1 flex items-center min-w-0 relative">
                                <div className="truncate w-11/12 pr-6">
                                  <p className="text-sm font-medium text-gray-900 truncate text_theme">
                                    {person.name}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate setting_menu_link">{`${person.handle}`}</p>
                                </div>

                                <span className="flex-shrink-0 inline-block relative ml-3 inline-flex sm:ml-0">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left"
                                  >
                                    {({ open3 }) => (
                                      <>
                                        <Menu.Button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 icon_bg border_none_theme">
                                          <span className="sr-only">
                                            Open options menu
                                          </span>
                                          <DotsVerticalIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        <Transition
                                          show={open3}
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items
                                            static
                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                                          >
                                            <div className="py-1">
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <Link
                                                    to="#"
                                                    className={classNames(
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                      "block px-4 py-2 text-base flex"
                                                    )}
                                                  >
                                                    <AnnotationIcon
                                                      className="-ml-0.5 mr-2 h-5 w-5 text-indigo-600 mt-1"
                                                      aria-hidden="true"
                                                    />{" "}
                                                    Mark as Unread
                                                  </Link>
                                                )}
                                              </Menu.Item>
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <Link
                                                    to="#"
                                                    className={classNames(
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                      "block px-4 py-2 text-base flex"
                                                    )}
                                                  >
                                                    <TrashIcon
                                                      className="-ml-0.5 mr-2 h-5 w-5 text-indigo-600"
                                                      aria-hidden="true"
                                                    />{" "}
                                                    Delete
                                                  </Link>
                                                )}
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </>
                                    )}
                                  </Menu>
                                </span>
                              </div>
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="relative h-16 w-full">
                      <div className="absolute bottom-0 left-0 h-16 w-full text-center text-indigo-600 pt-4">
                        <Link to="/notifications">See All Notifications</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Header;
