import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import ActionButton from "./ActionButton";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

function ActionButtonPopUp({ btnGroups, handleAction }) {
  return (
    <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
      <span className="ml-3 inline-flex sm:ml-0">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Open options menu</span>
                <DotsVerticalIcon className="h-6 w-6" aria-hidden="true" />
              </Menu.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                >
                  <div className="py-1">
                    {btnGroups.map((btn) => {
                      return (
                        <ActionButton
                          key={btn.id}
                          btnTitle={btn.title}
                          btnType={btn.type}
                          btnPath={btn.path}
                          handleAction={handleAction}
                        />
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </span>
    </div>
  );
}

ActionButtonPopUp.propTypes = {
  btnGroups: PropTypes.func,
  // "btnGroups.map": PropTypes.instanceOf(Array),
};

ActionButtonPopUp.defaultProps = {
  btnGroups: () => {
     // do nothing.
  },
  // "btnGroups.map": [],
};

export default ActionButtonPopUp;
