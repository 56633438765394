import PropTypes from "prop-types";
import React, {useState, useEffect } from "react";
import { Link } from "react-router-dom";
import labels from "../../constants/SignIn";
import Label from "../../components/Form/Label";
import configuration from '../../config';
import { toast } from 'react-toastify';
import {reactLocalStorage} from 'reactjs-localstorage';

var jwt = require('jsonwebtoken');

function View({ history }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.email) {
      error['email'] = "Please enter email"
      // toast.error("Please enter email");
      flag = false
    }
    if (!fields.password) {
      error['password'] = "Please enter password"
      // toast.error("Please enter password");
      flag = false
    }
    setErrors({...error })
    return flag;
  }
  function generateToken(user) {
    var object = {
        email: user.email,
        name:user.name,
        user_id:user.user_id,
        user_type:user.role,
        photo:user.photo
    };
    // console.log(object)
    return jwt.sign(object, configuration.appName, {
        expiresIn: '1d'
    });
  }
  useEffect(() => {
    var token = reactLocalStorage.get('token');
    try {
        jwt.verify(token, configuration.appName, function (err, decoded){
            if (err){
                decoded = null;
                history.push("/")
              }
              else{
                history.push("/dashboard")
              }
            });
    } catch(err) {
        history.push("/")
    }
  }, []);

  const handleSubmit = () =>{
    // console.log("fields");
    // console.log(fields);
    if (validation()) {
      configuration.postAPI({url:'auth/sign-in', params:fields}).then((data) => {
        // console.log(data)
        if(data.status === 200){
          reactLocalStorage.set('user_id', data.payload.user_id);
          reactLocalStorage.set("email", data.payload.email);
          if (data.payload.is_two_step_verification) {
            history.push("/otp");
            return;
          }
          var token = generateToken(data.payload);
          reactLocalStorage.set('token', token);
          reactLocalStorage.set('loginlog_id', data.payload.loginlog_id);
          history.push("/dashboard")
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 page_bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
   
          src="../image/logo-green.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 text_theme">
          {labels.signInToYourAccount}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 box_bg">
          <form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700 text-base text_theme"
              >
                {labels.emailAddress}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e)=>setFields({...fields, email: e.target.value})}
                  value={fields.email}
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                />
              </div>
              {errors.email ? <Label title={errors.email} fieldError={errors.email} /> : null}
            </div>

            <div>
              <label
                htmlFor="password"
                className="block font-medium text-gray-700 text-base text_theme"
              >
                {labels.password}
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e)=>setFields({...fields, password: e.target.value})}
                  value={fields.password}
                  autoComplete="current-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                />
              </div>
              {errors.password ? <Label title={errors.password} fieldError={errors.password} /> : null}
            </div>

            <div className="flex items-center justify-between">
              {/*<div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm">
                  {labels.rememberMe}
                </label>
              </div>*/}

              <div className="text-sm">
                <Link
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  to="/forgot-password"
                >
                  {labels.forgotYourPassword}
                </Link>
              </div>
            </div>

            <div>
              <button
                type="button"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSubmit}
              >
                {labels.signIn}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  history: PropTypes.func,
  // "history.push": PropTypes.func,
};

View.defaultProps = {
  history: () => {
     // do nothing.
  },
  // "history.push": () => {
  //    // do nothing.
  // },
};

export default View;
