import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import { Link } from "react-router-dom";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/Trip";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    {
      title: "ID",
      field: "trip_id",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={{     
            pathname: `/tripsdetail`,
            state:rowData.trip_id
          }}
          // to={`/tripsdetail/${rowData.trip_id}`}
        >
          {rowData.trip_id}
        </Link>
      ),
    },
    {
      title: "Passanger",
      field: "p_first_name",
      render: (rowData) => (
        <span>
          {rowData.p_first_name} {rowData.p_last_name}
        </span>
      ),
    },
    {
      title: "Driver",
      field: "d_first_name",
      render: (rowData) => (
        (rowData.d_first_name && rowData.d_first_name !== '')?<span>
          {rowData.d_first_name} {rowData.d_last_name}
        </span>:<span> Not Assign </span>
      ),
    },
    {
      title: "Service",
      field: "trip_id",
      render: (rowData) => (
        <span>
          {rowData.service_info.title}
        </span>
      ),
    },
    { title: "Trip Date", field: "trip_date" }, 
    {
      title: "Subtotal",
      field: "subtotal",
      render: (rowData) => (
        <span>
          {rowData.currency} {Number(rowData.subtotal).toFixed(2)}
        </span>
      ),
    },
    { title: "KM ", field: "formatted_total_distance" },
    {
      title: "Total",
      field: "total",
      render: (rowData) => (
        <span>
          {rowData.currency} {Number(rowData.total).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        let status = rowData.status;
        status = status.split('_').join(' ');
        return (<span
          className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 capitalize"
        >
          {status}
        </span>)},
    },
    {
      title: "Reports",
      field: "trip_id",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={{     
            pathname: `/tripreports`,
            state:rowData.trip_id
          }}
          // to={`/tripsdetail/${rowData.trip_id}`}
        >
          View Reports
        </Link>
      ),
    },
  ];

  const btnGroups = [];
  const [data, setData] = useState([]);
  
  useEffect(() => {
    configuration.getAPI({url:'trip/list', params:{}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.trips} />
      <ListSection
        captionTitle="View Trips"
        columns={columns}
        data={data}
        disabledCheckbox={true}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
