import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import tableIcons from './miui-icons'

// Table
function DataTable({ title=false, columns, data, onSelectionChange, disabledCheckbox, disabledPagination, disabledSearch}) {
  const [selectedRow] = useState(null);

  let pageSizeOptions = []
  addPush(5)
  addPush(10)
  addPush(20)
  addPush(50)
  addPush(100)
  addPush(200)
  addPush(500)
  addPush(1000)
  function addPush(number) {
    if (data.length > number) {
      pageSizeOptions.push(number)
    }
  }
  pageSizeOptions.push({ value: data.length, label: 'All' })
  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      data={data}
      title={title}
      pagination
      paginationMode="server"
      options={{
        search: (disabledSearch) ? false : true,
        selection: (disabledCheckbox) ? false : true,
        sorting: true,
        paging: (disabledPagination) ? false : true,
        pageSizeOptions:pageSizeOptions,
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
      }}
      onSelectionChange={onSelectionChange}
    />
  );
}

DataTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  onSelectionChange: PropTypes.func,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  onSelectionChange: () => {
    // do nothing.
  },
};

export default DataTable;
