import React, { useState, useEffect } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck } from "underscore";
import ListSection from "../../components/ListSection";
import labels from "../../constants/RideOption";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all roles
function View({location}) {
  const columns = [
    {
      title: "ID",
      field: "vehicle_ride_option_id",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={`/rideoption/edit/${rowData.vehicle_ride_option_id}`}
        >
          {rowData.vehicle_ride_option_id}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    { title: "Code", field: "code" },
    { title: "Capacity ", field: "max_seats" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "active"
              ? "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800"
              : "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800"
          }
        >
          {" "}
          {rowData.status === "active" ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];

  const btnGroups = [
    { id: 1, title: "Add New", type: "addNew", path: "/rideoption/add" },
    { id: 2, title: "Make Active", type: "makeActive", path: "/rideoption" },
    { id: 3, title: "Make Inactive", type: "makeInactive", path: "/rideoption" },
    { id: 4, title: "Archive", type: "archive", path: "/rideoption" },
    { id: 5, title: "Delete", type: "delete", path: "/rideoption" },
  ];
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    // console.log(props.location)
    if (location.state && location.state.is_added) {
      setShow(true)
      setTimeout(
        () => setShow(false),
        3000
      );
    }
    getDatas();
    // console.log(configuration.baseURL);
  }, [location.state]);

  function getDatas(){
    configuration.getAPI({url:'ride-option/list', params:{}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function handleAction(type){
    if (ids.length < 1) {
      return toast.error('Please select at least one record')
    }
    const sendData={
      ids,
      type:(type === 'makeActive')?'active':(type === 'makeInactive')?'inactive':type
    }
    configuration.postAPI({url:'ride-option/action', params:sendData}).then((data) => {
      if(data.status === 200){
        getDatas()
        setIds([]);
        return toast.success((type === 'delete') ?'Record deleted successfully':'Record update successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'vehicle_ride_option_id');
    setIds(idArr)
  }
  return (
    <>
      <Breadcrumb module={labels.rideOption} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <ListSection
        captionTitle="Ride Option"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
        handleAction={handleAction}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
}

export default View;
