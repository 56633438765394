import React, { Fragment, useState, useEffect } from "react";
import configuration from '../../../config';
import Dropdown from "../../../components/Form/Dropdown";
import TextInput from "../../../components/Form/TextInput";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { toast } from 'react-toastify';
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

let Id = "";
// List Settings
// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
function Summary({ user_id }) {
    const [profile, setProfile] = useState({});
    const [fields, setFields] = useState({});
    const [resultData, setResultData] = useState({});
    const [open, setOpen] = useState(false)
    let option = [
        { id: 1, title: "Pending", value: "pending" },
        { id: 2, title: "Approved", value: "approved" },
        { id: 3, title: "Rejected", value: "rejected" },
        { id: 4, title: "Inprogress", value: "inprogress" }
    ];

    useEffect(() => {
        // console.log(props.location)
        Id = user_id
        configuration.getAPI({ url: 'app-user/detail', params: { user_id: Id } }).then((data) => {
            // console.log(data)
            if (data.status === 200) {
                let profileData = {
                    'First Name': data.payload.first_name,
                    'Last Name': data.payload.last_name,
                    'Email': data.payload.email,
                    'Mobile': `${(data.payload.mobile_country_code) ? data.payload.mobile_country_code : ''} ${data.payload.mobile}`,
                    'Reg Date': data.payload.created_at,
                    'Status': data.payload.status,
                    'Gender': data.payload.gender,
                    'Average Rating': Number(data.payload.average_ratings).toFixed(1)
                }
                if (data.payload.social_security_number && data.payload.social_security_number) {
                    profileData['Social Security Number'] = data.payload.social_security_number
                }
                if (data.payload.device_details && data.payload.device_details.device_name) {
                    profileData['Device Name'] = data.payload.device_details.device_name
                }
                if (data.payload.device_details && data.payload.device_details.device_type) {
                    profileData['Device Type'] = data.payload.device_details.device_type
                }
                if (data.payload.device_details && data.payload.device_details.device_token) {
                    profileData['Device Token'] = data.payload.device_details.device_token
                }
                setFields({ background_check: (data.payload.background_check) ? data.payload.background_check : '', background_note: (data.payload.background_note) ? data.payload.background_note : '', user_id: Id })
                setProfile(profileData)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);

    function handleChange(field, e) {
        setFields({ ...fields, [field]: e.target.value })
    }

    const handleSubmit = () => {
        console.log(fields)
        configuration.postAPI({ url: 'app-user/update-background-check', params: fields }).then((data) => {
            if (data.status === 200) {
                return toast.success('Update successfully')
            } else if (data.status === 404) {
                return toast.error(data.error.message)
            } else {
                return toast.error('Something went wrong')
            }
        }).catch(error => {
            return toast.error(error.message)
        });
    }

    const handleCheckBackground = () => {
        console.log(fields)
        configuration.postAPI({ url: 'app-user/background-check', params: fields }).then((data) => {
            if (data.status === 200) {
                setResultData(data.payload)
                setOpen(true);
            } else if (data.status && data.error.message) {
                return toast.error(data.error.message)
            } else {
                return toast.error('Something went wrong')
            }
        }).catch(error => {
            return toast.error(error.message)
        });
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen(true)}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div> */}
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                Background Check
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                                    < div className="">
                                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                            <div key={'status'} className="sm:col-span-1">
                                                                <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                                <dd className={classNames("mt-1 text-sm text-gray-900 md:break-all capitalize")}>{resultData.status}</dd>
                                                            </div>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="md:pl-8 sm:py-7 col-span-10">
                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Summary
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user summary.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            < div className="">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    {Object.keys(profile).map((field) => (
                                        <div key={field} className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">{field}</dt>
                                            <dd className={classNames("mt-1 text-sm text-gray-900 md:break-all", (field !== 'Email') ? "capitalize" : '')}>{profile[field]}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                </section> <br />
                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Background Check
                            </h2>
                            {/* <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information is show user summary.</p> */}
                            <div className="flex justify-end">
                                <BtnSaveRecord title={"Check Background with Checkr"} handleSubmit={handleCheckBackground} path="/driverprofile" />
                            </div>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            < div className="">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                    <div key='background_check' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                                        <div className="mt-1">
                                            <Dropdown
                                                id={'background_check'}
                                                value={fields['background_check']}
                                                name={'background_check'}
                                                handleChange={handleChange}
                                                status={option || []}
                                            />
                                        </div>
                                        {/* <dd className={classNames("mt-1 text-sm text-gray-900 md:break-all capitalize")}>Approve</dd> */}
                                    </div>
                                    <div key='background_check' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Note</dt>
                                        <div className="mt-1">
                                            <TextInput
                                                id={'background_note'}
                                                name={'background_note'}
                                                value={fields['background_note']}
                                                handleChange={handleChange}
                                            />
                                        </div>
                                        {/* <dd className={classNames("mt-1 text-sm text-gray-900 md:break-all capitalize")}></dd> */}
                                    </div>

                                </dl>
                                <div className="pt-5 border_color_theme">
                                    <div className="flex justify-end">
                                        <BtnSaveRecord title={"Update"} handleSubmit={handleSubmit} path="/driverprofile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Summary;
