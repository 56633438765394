import React, { useState, useEffect } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import Label from "../../components/Form/Label";
import labels from "../../constants/Price";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each, without } from 'underscore';

// List all users
function View({ location }) {
  const [normalPrice, setNormalPrice] = useState([]);
  const [advancedPrice, setAdvancedPrice] = useState({});
  const [cityToCityPrice, setCityToCityPrice] = useState([]);
  const [currTime, setCurrTime] = useState(new Date());
  const [rides, setRides] = useState([]);
  const [slots, setSlots] = useState([]);
  const [services, setServices] = useState([]);
  const [applied_days, setAppliedDays] = useState([]);
  const [days, setDays] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const [timeSlot, setTimeSlot] = useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: []
  });
  const [fields, setFields] = useState({
    service_id: ''
  });
  const [showPrice, setShowPrice] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    configuration.getAPI({ url: 'service/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setServices(data.payload)
        setFields({...fields, service_id: data.payload[0].service_id})
        getRideOptions(data.payload[0].service_id)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    configuration.getAPI({ url: 'price/slots', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setSlots(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // getStateDatas();
  }, []);

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.service_id) {
      error['service_id'] = "Please select service"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }

  function getRideOptions(service_id){
    configuration.getAPI({ url: 'ride-option/list', params: { status: 'active', service_id } }).then((data) => {
      if (data.status === 200) {
        setRides(data.payload)
        for (var i = 0; i < data.payload.length; i++) {
          normalPrice[data.payload[i].vehicle_ride_option_id] = {}
          cityToCityPrice[data.payload[i].vehicle_ride_option_id] = {}
        }
        for (var i = 0; i < days.length; i++) {
          let obj = {};
          for (var k = 0; k < data.payload.length; k++) {
            obj[data.payload[k].vehicle_ride_option_id] = {};
          }
          advancedPrice[days[i]] = obj
        }
        setAdvancedPrice(advancedPrice)
        setNormalPrice(normalPrice)
        setCityToCityPrice(cityToCityPrice)
        getNormalPrice(service_id, data.payload)
        getAdvancedPrice(service_id, data.payload)
        getCityToCityPrice(service_id, data.payload)
        setTimeout(() => {
          console.log('This will run after 3 second!')
          setShowPrice(true)
        }, 1000);
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function getNormalPrice(service_id, ridesData) {
    configuration.getAPI({ url: 'price/price', params: { service_id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload === 'empty') {
          let obj = {};
          //console.log(ridesData)
          each(ridesData, single => {
            obj[single.vehicle_ride_option_id] = {
              "base_fare": 0,
              "regulatory_recovery_fee": 0,
              "service_fee": 0,
              "cost_per_mile": 0,
              "cost_per_min": 0,
              "min_base_mile": 0,
              "min_base_fare": 0,
              "wait_fees": 0,
              "wait_fees_per_min": 0,
              "cancellation_fee": 0,
              "incentives_multiplier": 0,
            }
          })
          // console.log(obj)
          setShowPrice(true)
          setNormalPrice(obj);
        } else {
          setNormalPrice(data.payload);
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // setCurrTime(new Date())
  }

  function getCityToCityPrice(service_id, ridesData) {
    configuration.getAPI({ url: 'price/city-price', params: { service_id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload === 'empty') {
          let obj = {};
          console.log(ridesData)
          each(ridesData, single => {
            obj[single.vehicle_ride_option_id] = {
              "base_fare": 0,
              "regulatory_recovery_fee": 0,
              "regulatory_recovery_fee": 0,
              "service_fee": 0,
              "cost_per_mile": 0,
              "cost_per_min": 0,
              "min_base_mile": 0,
              "min_base_fare": 0,
              "wait_fees": 0,
              "wait_fees_per_min": 0,
              "cancellation_fee": 0,
              "incentives_multiplier": 0,
            }
          })
          // console.log(obj)
          setShowPrice(true)
          setCityToCityPrice(obj);
        } else {
          setCityToCityPrice(data.payload);
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // setCurrTime(new Date())
  }

  function getAdvancedPrice(service_id, ridesData) {
    configuration.getAPI({ url: 'price/slot-price', params: { service_id } }).then((data) => {
      if (data.status === 200) {
        if (data.payload === 'empty') {
          let mainObj = {};
          let timeSlotObj = {};
          each(days, elem => {
            let obj = {};
            each(ridesData, element => {
              obj[element.vehicle_ride_option_id] = {
                "base_fare": 0,
                "regulatory_recovery_fee": 0,
                "service_fee": 0,
                "cost_per_mile": 0,
                "cost_per_min": 0,
                "min_base_mile": 0,
                "min_base_fare": 0,
                "wait_fees": 0,
                "wait_fees_per_min": 0,
                "cancellation_fee": 0,
                "incentives_multiplier": 0,
              }
            })
            mainObj[elem] = obj
            timeSlotObj[elem] = []
          })
          setAdvancedPrice(mainObj);
          setTimeSlot(timeSlotObj);
          setAppliedDays([])
        } else {
          setAdvancedPrice(data.payload.advancedPrice);
          setTimeSlot(data.payload.timeSlot);
          setAppliedDays(data.payload.applied_days)
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // setCurrTime(new Date())
  }
  function handleChangeNormal(e, field, title) {
    normalPrice[title][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
    setNormalPrice(normalPrice);
    setCurrTime(new Date())
  }

  function handleChangeCity(e, field, title) {
    cityToCityPrice[title][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
    setCityToCityPrice(cityToCityPrice);
    setCurrTime(new Date())
  }

  function handleChangeAdvanced(e, field, title, day) {

    advancedPrice[day][title][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
    setAdvancedPrice(advancedPrice)
    setCurrTime(new Date())
  }

  function handleChangeAppliedDay(e, day) {
    console.log(applied_days)
    if (applied_days.includes(day) === false) {
      // applied_days.push(day);        
      setAppliedDays([...applied_days, day])
    }
    else {
      // console.log(without(applied_days, day))
      setAppliedDays(without(applied_days, day))
    }
  }

  function applyNormalPrice() {
    if (handleValidation()) {
      console.log(fields)
      configuration.postAPI({ url: "price/normal-price", params: { ...fields, normal_price: normalPrice } }).then((data) => {
        if (data.status === 200) {
          applyCityToCityPrice()
          applyAdvancedPrice()
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }

  function applyCityToCityPrice() {
    if (handleValidation()) {
      console.log(fields)
      configuration.postAPI({ url: "price/city-to-city-price", params: { ...fields, city_price: cityToCityPrice } }).then((data) => {
        if (data.status != 200 && data.error) {
          return toast.error(data.error.message);
        }
      })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }

  function applyAdvancedPrice() {
    console.log(fields)
    configuration.postAPI({ url: "price/day-slot-price", params: { ...fields, advancedPrice, timeSlot, applied_days } }).then((data) => {
      if (data.status === 200) {
        getNormalPrice(fields.service_id, rides)
        getAdvancedPrice(fields.service_id, rides)
        return toast.success('Updated successfully');
        // setData(data.payload)
      } else if (data.error) {
        return toast.error(data.error.message);
      } else {
        return toast.error("Something went wrong");
      }
    })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleChangeTimeSlot(e, field, day) {
    const weekDay = timeSlot[day];
    if (weekDay.includes(e.target.value) === false) {
      weekDay.push(e.target.value);
    }
    else {
      weekDay.splice(weekDay.indexOf(e.target.value), 1);
    }
    console.log(weekDay)
    setTimeSlot(timeSlot)
    setCurrTime(new Date())
  }
  return (
    <>
      <Breadcrumb module={labels.price} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                      Trip Cost
                      </h2>
                    </div>
                  </div>

                  <div className="pt-5 border_color_theme">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8 m-5">
                        <div className="sm:col-span-3" key={"Service"}>
                          <Label title={"Service"} />
                          <div className="mt-1">
                            <select
                              id={'service_id'}
                              name={'service_id'}
                              value={fields.service_id}
                              onChange={(e) => {
                                setFields({ ...fields, service_id: e.target.value })
                                getRideOptions(e.target.value)
                              }}
                              autoComplete="service_id"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
                            >
                              {services.map((opt) => {
                                return (
                                  <option value={opt.service_id} key={opt.service_id}>
                                    {opt.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors['service_id'] ? (
                            <Label
                              title={'Services'}
                              fieldError={errors['service_id']}
                            />
                          ) : null}
                        </div>
                        <div className="pt-5 border_color_theme">
                          <div className="flex justify-end">
                            <BtnSaveRecord handleSubmit={applyNormalPrice} title="Submit" path="/user" />
                          </div>
                        </div>
                      </div>
                      {showPrice ?
                        <div className="lg:gap-y-0 lg:gap-x-8">
                          <h3 className="foodie-hd-top-space pt30 pl-3 font-bold text-lg">Book Now</h3>
                          <div class="table-responsive py-3 px-3">
                            <table class="table-outline mb-0  d-sm-table table table-hover" >
                              <thead class="thead-light">
                                {/* <tr>					        
										                <th style={{border: '1px solid #cecece',borderBottom: '0',width:'300px',backgroundColor: '#f2f2f2'}}></th>
										                <th colspan="5" className="bg-indigo-600 text-white border-gray-200 border" style={{borderColor: 'rgb(164, 183, 193)'}}>Fare Estimate</th>
										                <th colspan="4" className="bg-indigo-600 text-white border-gray-200 border" style={{borderColor: 'rgb(164, 183, 193)'}}>Schedule Ride</th>
											            </tr> */}
                                <tr className="bg-gray">
                                  <th style={{ width: '300px', background: 'transparent', backgroundColor: '#f2f2f2', }}>Vehicle</th>
                                  <th width="200px">Base Fare</th>
                                  <th width="200px">Regulatory Fee</th> 
                                  <th width="200px">Service Fee</th>
                                  <th width="200px">Cost Per Mile</th>
                                  <th width="200px">Cost Per Min</th>
                                  <th width="200px">Min.Base Mile</th>
                                  <th width="200px">Min.Base Fare</th>
                                  <th width="200px">Free Wait Mins</th>
                                  <th width="200px">Wait Fees Per Min</th>
                                  <th width="200px">Cancellation Fee</th>
                          
                                </tr>
                              </thead>
                              <tbody>{console.log(rides)}
                                { 
                                  rides.map((option, key) => {
                                    return (<tr className="paddy">
                                      <td width="125px">{option.title}</td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'base_fare', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['base_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'regulatory_recovery_fee', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['regulatory_recovery_fee'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'service_fee', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['service_fee'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'cost_per_mile', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['cost_per_mile'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'cost_per_min', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['cost_per_min'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'min_base_mile', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['min_base_mile'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'min_base_fare', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['min_base_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'wait_fees', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['wait_fees'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'wait_fees_per_min', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['wait_fees_per_min'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeNormal(e, 'cancellation_fee', option.vehicle_ride_option_id)} value={(normalPrice[option.vehicle_ride_option_id]) ? normalPrice[option.vehicle_ride_option_id]['cancellation_fee'] : ''} /></td>

                                    </tr>)
                                  })
                                }
                              </tbody>
                            </table>
                          </div>

                          <h3 className="foodie-hd-top-space pt30 pl-3 font-bold text-lg">Scheduled Rides </h3>
                          <div class="table-responsive py-3 px-3">
                            <table class="table-outline mb-0  d-sm-table table table-hover" >
                              <thead class="thead-light">
                                {/* <tr>					        
										                <th style={{border: '1px solid #cecece',borderBottom: '0',width:'300px',backgroundColor: '#f2f2f2'}}></th>
										                <th colspan="5" className="bg-indigo-600 text-white border-gray-200 border" style={{borderColor: 'rgb(164, 183, 193)'}}>Fare Estimate</th>
										                <th colspan="4" className="bg-indigo-600 text-white border-gray-200 border" style={{borderColor: 'rgb(164, 183, 193)'}}>Schedule Ride</th>
											            </tr> */}
                                <tr className="bg-gray">
                                  <th style={{ width: '300px', background: 'transparent', backgroundColor: '#f2f2f2', }}>Vehicle</th>
                                  <th width="200px">Base Fare</th>
                                  <th width="200px">Regulatory Fee</th>
                                  <th width="200px">Service Fee</th>
                                  <th width="200px">Cost Per Mile</th>
                                  <th width="200px">Cost Per Min</th>
                                  <th width="200px">Min.Base Mile</th>
                                  <th width="200px">Min.Base Fare</th>
                                  <th width="200px">Free Wait Mins</th>
                                  <th width="200px">Wait Fees Per Min</th>
                                  <th width="200px">Cancellation Fee</th>
                               
                                </tr>
                              </thead>
                              <tbody>{console.log(rides)}
                                { 
                                  rides.map((option, key) => {
                                    return (<tr className="paddy">
                                      <td width="125px">{option.title}</td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'base_fare', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['base_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'regulatory_recovery_fee', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['regulatory_recovery_fee'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'service_fee', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['service_fee'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'cost_per_mile', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['cost_per_mile'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'cost_per_min', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['cost_per_min'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'min_base_mile', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['min_base_mile'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'min_base_fare', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['min_base_fare'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'wait_fees', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['wait_fees'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'wait_fees_per_min', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['wait_fees_per_min'] : ''} /></td>
                                      <td><input type="number" class="table_input form-control" onChange={(e) => handleChangeCity(e, 'cancellation_fee', option.vehicle_ride_option_id)} value={(cityToCityPrice[option.vehicle_ride_option_id]) ? cityToCityPrice[option.vehicle_ride_option_id]['cancellation_fee'] : ''} /></td>
                                      
                                    </tr>)
                                  })
                                }
                              </tbody>
                            </table>
                          </div>

                          
                        </div> : ''
                      }
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
        </div>
      </div >
    </>
  );
}

export default View;
