import React, { useState, useEffect } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import commonConstant from "../../constants/Common";
import labels from "../../constants/Help";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Add Help
function Add({ history }) {
  const [fieldArr, setFieldArr] = useState([]);
  const [fields, setFields] = useState({});
  const [answer, setAnswer] = useState({});
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({});
  const [catArr, setCatArr] = useState([]);
  const [columnArr2, setColumnArr2] = useState([]);

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  let columnArr = [
    {
      id: 0,
      title: "User Type",
      name: "user_type",
      type: "Dropdown",
      options: commonConstant.user_type,
    },
    {
      id: 1,
      title: "Help Category",
      name: "help_category_id",
      type: "Dropdown",
      options: catArr,
    },
    // { id: 2, title: "Order no", name: "order_no", type: "TextInput" },
    { id: 3, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    

    // configuration.getAPI({ url: 'help-category/list', params: { status: "active" } }).then((data) => {
    //   if (data.status === 200) {
    //     let catArr = []
    //     UND.each(data.payload, (singleRec) => {
    //       catArr.push({ 'title': singleRec.title['EN'], 'value': singleRec.help_category_id, 'id': singleRec.help_category_id })
    //     })
    //     const hlpCat = {
    //       id: 1,
    //       title: "Help Category",
    //       name: "help_category_id",
    //       type: "Dropdown",
    //       options: catArr,
    //     }
    //     columnArr.push(hlpCat)
    //   }
    // }).catch(error => {
    //   return toast.error(error.message)
    // });

    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let que_arr = [];
        let ans_arr = [];
        let link_arr = [];
        languages.map((language, index) => {
          que_arr.push({ id: index, title: "Question[" + language.title + "]", name: "question_" + language.code, type: "TextInput" })
          ans_arr.push({ id: index, title: "Answer[" + language.title + "]", name: "answer_" + language.code, type: "TextArea", is_full_screen:true })
          // link_arr.push({ id: index, title: "Link[" + language.title + "]", name: "link_" + language.code, type: "TextInput" })
        })
        configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'helps' } }).then((data1) => {
          if (data.status === 200) {
            let orderNoArr = []
            UND.each(data1.payload, (singleRec) => {
              orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
            })
            const orderNoObj = [{
              id: 1,
              title: "Order No",
              name: "order_no",
              type: "Dropdown",
              options: orderNoArr,
            }]
            // columnArr.push(orderNoObj)
            setColumnArr2([...orderNoObj, ...que_arr, ...ans_arr])
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        // const fullCoulnmArr = columnArr.concat(que_arr, ans_arr);
        // setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function getHelpCategory(user_type){
    configuration.getAPI({ url: 'help-category/list', params: { status: "active", user_type } }).then((data) => {
      if (data.status === 200) {
        let newCatArr = []
        UND.each(data.payload, (singleRec) => {
          newCatArr.push({ 'title': singleRec.title['EN'], 'value': singleRec.help_category_id, 'id': singleRec.help_category_id })
        })
        setCatArr(newCatArr)
        // const hlpCat = {
        //   id: 1,
        //   title: "Help Category",
        //   name: "help_category_id",
        //   type: "Dropdown",
        //   options: catArr,
        // }
        // columnArr.push(hlpCat)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.user_type) {
      error['user_type'] = "Please select user type"
      flag = false
    }
    if (!fields.order_no) {
      error['order_no'] = "Please enter order no"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    if (!fields.help_category_id) {
      error['help_category_id'] = "Please select help category"
      flag = false
    }
    UND.each(languages, (lang, ind) => {
      if (!fields['question_' + lang.code]) {
        error['question_' + lang.code] = "Please enter question in " + lang.title
        flag = false
      }
      // if (!fields['answer_' + lang.code]) {
      //   error['answer_' + lang.code] = "Please enter answer in " + lang.title
      //   flag = false
      // }

      // if (!fields['link_' + lang.code]) {
      //   error['link_' + lang.code] = "Please enter link in " + lang.title
      //   flag = false
      // }
    })
    setErrors({ ...error })
    return flag;
  }


  function handleChange(field, e) {
    if(field === 'user_type'){
      getHelpCategory(e.target.value);
    }
    setFields({ ...fields, [field]: e.target.value })
  }

  // function handleChange(field, e) {
  //   if (field.search("answer") >= 0) {
  //     setAnswer({ ...answer, [field]: e.target.getContent()})
  //   } else {
  //     setFields({ ...fields, [field]: e.target.value })
  //   }
  // }

  const handleSubmit = () => {
    const fullObj = {
      ...answer,
      ...fields
    };
    if (handleValidation()) {
      console.log(fullObj)
      configuration.postAPI({ url: 'help/create', params: fullObj }).then((data) => {
        if (data.status === 200) {
          history.push('/help')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.addHelp} />
      <AddEditSection
        title="Add Help"
        path="/help"
        fields={[...columnArr, ...columnArr2]}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
