import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../constants/PassengerMembership";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// For Edit State
let Id = "";
function Edit({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [fieldNewArr, setFieldNewArr] = useState([])
  const [fieldOrderArr, setFieldOrderArr] = useState([])
  const fieldArr = [
    { id: 0, title: "Code ", name: "code", type: "TextInput", is_read_only:true },
    // { id: 1, title: "Complate Ride Criteria Days ", name: "complate_ride_criteria_days", type: "TextInput", is_number:true },
    { id: 2, title: "Complate Ride From Criteria ", name: "complate_ride_from_criteria", type: "TextInput", is_number:true },
    { id: 3, title: "Complate Ride To Criteria ", name: "complate_ride_to_criteria", type: "TextInput", is_number:true },
    { id: 4, title: "Credit ", name: "recieve_credit", type: "TextInput", is_number:true },
    // { id: 5, title: "Refered Passenger Complete Ride Criteria", name: "refered_passenger_complete_ride", type: "TextInput", is_number:true },
    // { id: 6, title: "Referal Passenger Credit ", name: "refer_passenger_credit", type: "TextInput", is_number:true },
    // { id: 7, title: "Referal Driver Credit ", name: "refer_driver_credit", type: "TextInput", is_number:true },
    { id: 8, title: "Five Star Club Members cut-off for every ride ", name: "five_star_club_cut_off", type: "TextInput", is_number:true },
    {
      id: 9,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ];
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  function handleValidation() {
    let flag = true;
    let error = {}
    if ( fields.code !== 'BASIC' && !fields.recieve_credit) {
      error['recieve_credit'] = "Please enter credit"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please enter code"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'subscription/update', params:{...fields, type:'passenger'}}).then((data) => {
        if(data.status === 200){
          history.push('/passengermembership')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'subscription/list', params:{subscription_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'subscriptions', is_update: 1 } }).then((data) => {
      if (data.status === 200) {
        let orderNoArr = []
        each(data.payload, (singleRec) => {
          orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
        })
        const orderNoObj = {
          id: 14,
          title: "Order No",
          name: "order_no",
          type: "Dropdown",
          options: orderNoArr,
        }
        // fieldArr.push(orderNoObj)
        setFieldOrderArr([orderNoObj])
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 15 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 18 + index,
            title: `Short Description [${language.title}]`,
            name: `short_desc_${language.code}`,
            type: "TextInput"
          });
        })
        // languages.map((language, index) => {
        //   fieldArr_data.push({
        //     id: 9 + index,
        //     title: `Long Description [${language.title}]`,
        //     name: `long_desc_${language.code}`,
        //     type: "TextArea"
        //   });
        // })
        setFieldNewArr(fieldArr_data)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editPassengerMembership} />
      <AddEditSection title="Edit Passenger Membership" path="/passengermembership" fields={[...fieldNewArr,...fieldOrderArr,...fieldArr]} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
