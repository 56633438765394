import PropTypes from "prop-types";
import React, { useState } from "react";
import { Switch } from "@headlessui/react";

// Dynamic className
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// For Input Element
function SwitchInput({ id, name, handleChange, value, is_read_only }) {
  const [enabled, setEnabled] = useState(false);
  return (
    <Switch
      name={name}
      id={id}
      checked={value}
      readOnly={is_read_only || false}
      onChange={(e) =>
        name ? handleChange(name, e) : handleChange(e.target.value)
      }
      className={classNames(
        value ? "bg-indigo-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          value ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </Switch>
  );
}

SwitchInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

SwitchInput.defaultProps = {
  id: "",
  name: "",
};

export default SwitchInput;
