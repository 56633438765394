import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useState, useEffect} from "react";
import labels from "../../constants/Preference";
import configuration from '../../config';
import { toast } from 'react-toastify';
import {each} from 'underscore';

// For Add Role
function Add({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [languages, setLanguages] = useState([])
  const [fieldArr_data, setFieldArr_data] = useState([]);
  const fieldArr = [
    
    // { id: 0, title: "Title", name: "title", type: "TextInput" },
    // { id: 1, title: "Descriptions ", name: "description", type: "TextArea" },
    {
      id: 2,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'preference/create', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/Preference')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  setFields({ ...fields, [field]: e.target.value })
}
useEffect(() => {
  configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
    if (data.status === 200) {
      setLanguages(data.payload)
      let languages = data.payload;
      let fieldArrData = [];
      languages.map((language, index) => {
        fieldArrData.push({
          id: 3 + index,
          title: `Title [${language.title}]`,
          name: `title_${language.code}`,
          type: "TextInput"
        });
      })
      setFieldArr_data(fieldArrData)
      // setFieldArr([...fieldArr,...numberArray, ...fieldArr_data])
    }
  }).catch(error => {
    return toast.error(error.message)
  });
}, []);
  return (
    <>
      <Breadcrumb module={labels.AddPreference} />
      <AddEditSection title="Add Preference" path="/preference" fields={[...fieldArr_data, ...fieldArr]} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
