import { MailIcon, DeviceMobileIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import DataTable from "../../../components/MDataTable";
import { without } from 'underscore';

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let Id = "";
// List Settings
function Summary({ user_id, user_type }) {
    const [subscription, setSubscription] = useState({});
    const columns = [
        { title: "ID", field: "subscription_history_id"},
        
        { title: "Subscription", field: "subscription_code" },
        { title: "Start Date", field: "start_date" },
        { title: "End Date", field: "end_date" },
        {
          title: "Price",
          field: "price",
          render: (rowData) => (
            <span>
              {rowData.currency} {Number(rowData.price).toFixed(2)}
            </span>
          ),
        },
        {
          title: "Status",
          field: "status",
          render: (rowData) => (
            <span
              className={
                rowData.status === "active"
                  ? "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 capitalize"
                  : "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 capitalize"
              }
            >
              {" "}
              {rowData.status}
            </span>
          ),
        },
    ];
    const [data, setData] = useState([]);
    const onSelectionChange = (e) => {
        // let idArr = pluck(e, 'user_id');
        // setIds(idArr)
    }
    useEffect(() => {
        Id = user_id;
        configuration.getAPI({ url: 'app-user/subscription-detail', params: { user_id: Id, user_type } }).then((data) => {
            if (data.status === 200) {
                console.log(data.payload)
                setSubscription(data.payload)
            }
        }).catch(error => {
            return toast.error(error.message)
        });
        configuration.getAPI({ url: 'app-user/subscription-history', params: { user_id: Id, user_type } }).then((data) => {
            if (data.status === 200) {
                console.log(data.payload)
                setData(data.payload)
            }
        }).catch(error => {
            return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Memberships
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show user memberships.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        < div className="">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Memberships Status</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.memberships_status}</dd>
                                </div>
                                {(subscription.subscription_code && subscription.subscription_code !== '') ?
                                    <div key='status' className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Memberships</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.subscription_code}</dd>
                                    </div> : ''}
                                {(user_type === 'driver' && subscription.subscription_code && subscription.subscription_code !== '') ? <div key='status' className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Memberships Expire Date</dt>
                                    <dd className="mt-1 text-sm text-gray-900 md:break-all capitalize">{subscription.driver_subscription_expire_date}</dd>
                                </div> : ''
                                }
                            </dl>

                                <div className="border-t border-gray-200 py-5 mt-5">                                                                   
                                <DataTable
                                columns={without(columns, null)}
                                data={data}
                                disabledCheckbox={true}
                                onSelectionChange={onSelectionChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
