import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/State";

let Id = "";

// For Edit State
function Edit({ history }) {
  const statusOpt = [
    
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const [countryOpt, setCountryOpt] = useState([]);
  const fieldArr = [
    {
      id: 0,
      title: "Country",
      name: "country_id",
      type: "Dropdown",
      options: countryOpt,
    },
    { id: 1, title: "Title", name: "name", type: "TextInput" },
    { id: 2, title: "State Code", name: "state_code", type: "TextInput" ,isDisable: true},
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    country_id: "",
    state_code: "",
    status: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please Enter Name"
      flag = false
    }

    if (!fields.state_code) {
      error['state_code'] = "Please Enter State Code "
      flag = false
    }
    if (!fields.country_id) {
      error['country_id'] = "Please Select Country"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...errors, ...error })
    return flag;
  }

  function getCountryDatas() {
    configuration
      .getAPI({ url: "country/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.country_id;
              element.id = element.country_id;

              return element;
            });
          }
          setCountryOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  useEffect(() => {
    let url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "state/list", params: { state_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    getCountryDatas();
  }, []);

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }
  const handleSubmit = () => {
    if (handleValidation()) {

    configuration
      .postAPI({ url: "state/update", params: fields })
      .then((data) => {
        if (data.status === 200) {
          history.push("/state");
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editState} />
      <AddEditSection
        title="Edit State"
        path="/state"
        fieldValue={fields}
        fieldError={errors}

        fields={fieldArr}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
