import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary() {
    const [profile, setProfile] = useState({
        'First Name': 'ricardo',
        'Last Name': 'cooper',
        'Email ': 'ricardocooper@example.com',
        'Reg Type': 'Individual',
        'Mobile': '+1 8141613202',
        'Gender': 'Male',
        'Country ': 'India',
        'State': 'Gujarat',
        'City': 'Ahmedabad',
        'Date of Birth': 'June 8, 1990',
        'Reg Date': '07/01/2022, 04:16',
        'Ride Acceptance Rate': '0%',
        'Today Ride Acceptance Rate': '0%',
        'Status': 'Active',
        'Average Ratings': '1.5',
        'Device Name': 'SM-M215F',
        'Device Type': 'android',
        'Device Token': 'cWBeOYVXtDo:APA91bFssER6PiIK4H4pr7Gv_u1dhRnn9YmyJAgpomOmzjyaQsqg6zx_r3cygXJT U49SO9LG7KkHmrXO9JP9BCkfDlU5gLJHBq rIBkCPRjqnA_IuWFk7hIr3kaGEfSsayM6vrlQXdNUR',
    });

    useEffect(() => {
        // console.log(props.location)
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({url:'driver/detail', params:{driver_id:Id}}).then((data) => {
          // console.log(data)
          if(data.status === 200){
            let profileData = {
                'First Name': data.payload.first_name,
                'Last Name': data.payload.last_name,
                'Email ': data.payload.email,
                'Mobile': `${data.payload.mobile_country_code} ${data.payload.mobile}`,
                'Reg Date': data.payload.created_at,
                'Status':  data.payload.status,
                'Average Rating': data.payload.average_ratings 
            }
            if(data.payload.device_details && data.payload.device_details.device_name){
                profileData['Device Name'] = data.payload.device_details.device_name
            }
            if(data.payload.device_details && data.payload.device_details.device_type){
                profileData['Device Type'] = data.payload.device_details.device_type
            }
            if(data.payload.device_details && data.payload.device_details.device_token){
                profileData['Device Token'] = data.payload.device_details.device_token
            }
            setProfile(profileData)
          }
        }).catch(error => {
          // console.log(error)
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
      }, []);

    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Summary
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        < div className="">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                {Object.keys(profile).map((field) => (
                                    <div key={field} className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">{field}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 md:break-all">{profile[field]}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
