import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import Label from "../../../components/Form/Label";
import TextInput from "../../../components/Form/TextInput";
import BtnRedirect from "../../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../../components/Form/Button/BtnSaveRecord";
import { toast } from 'react-toastify';

// List Settings
function Summary({user_id}) {
    // const [trips, setTrips] = useState([]);
    const fieldArr1 = [
      {
        id: 0,
        title: "Password",
        name: "password",
        type: "TextInput",
        is_password: true,
      },
      {
        id: 1,
        title: "Confirm Password",
        name: "cpassword",
        type: "TextInput",
        is_password: true,
      },
    ];
    const [passFields, setPassFields] = useState({});
    const [passErrors, setPassErrors] = useState({
      password: "",
      cpassword: "",
    });
    function handleChangePass(field, e) {
      setPassFields({ ...passFields, [field]: e.target.value });
    }
    function validationPass() {
      let flag = true;
      let passErrors = {};
      if (!passFields.password) {
        passErrors["password"] = "Please enter password";
        flag = false;
      }
  
      if (!passFields.cpassword) {
        passErrors["cpassword"] = "Please enter confirm password";
        flag = false;
      }
  
      if (passFields.cpassword !== passFields.password) {
        passErrors["cpassword"] = "Password and confirm password doesn't match.";
        flag = false;
      }
      setPassErrors({ ...passErrors });
      return flag;
    }
    const handleChangePassword = () => {
      if (validationPass()) {
        console.log(passFields);
        configuration
          .postAPI({
            url: "user/change-password",
            params: { ...passFields, user_id },
          })
          .then((data) => {
            // console.log(data)
            if (data.status === 200) {
              // history.push('/user')
              setPassFields({ password: "", cpassword: "" });
              return toast.success("Password changed successfully");
            } else if (data.error) {
              return toast.error(data.error.message);
            } else {
              return toast.error("Something went wrong");
            }
          })
          .catch((error) => {
            // console.log(error)
            return toast.error(error.message);
          });
      }
    };
    useEffect(() => {
        // console.log(configuration.baseURL);
    }, []);
    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Change Password
                        </h2>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">                                                                                                                                                                                                                      
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {fieldArr1.map((field) => {
                          return (
                            <div
                              className="sm:col-span-3"
                              key={field.id}
                            >
                              <Label title={field.title} />
                              <div className="mt-1">
                                {field.type ===
                                "TextInput" ? (
                                  <TextInput
                                    id={field.name}
                                    name={field.name}
                                    handleChange={
                                      handleChangePass
                                    }
                                    value={
                                      passFields[field.name]
                                    }
                                    is_number={
                                      field.is_number || false
                                    }
                                    is_password={
                                      field.is_password ||
                                      false
                                    }
                                  />
                                ) : null}
                              </div>
                              {passErrors[field.name] ? (
                                <Label
                                  title={field.title}
                                  fieldError={
                                    passErrors[field.name]
                                  }
                                />
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                      <div className="pt-5 border_color_theme">
                        <div className="flex justify-end">
                          {/* <BtnRedirect
                            title="Cancel"
                            path="/user"
                          /> */}
                          <BtnSaveRecord
                            handleSubmit={
                              handleChangePassword
                            }
                            title="Submit"
                            path="/user"
                          />
                        </div>
                      </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
