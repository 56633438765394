import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../constants/Passenger";
import configuration from '../../config';
import { toast } from 'react-toastify';

// For Edit State
let Id = "";
function Edit({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const fieldArr = [
    { id: 0, title: "First Name ", name: "first_name", type: "TextInput" },
    { id: 0, title: "Last Name", name: "last_name", type: "TextInput" },
    { id: 0, title: "Email", name: "email", type: "TextInput" },    
    { id: 0, title: "Phone Number", name: "mobile", type: "TextInput" },
    { id: 0, title: "Photo", name: "profile_photo", type: "FileInput" },
    {
      id: 1,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ];
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  const [profile_photo, setPhoto] = useState(null);
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.first_name) {
      error['first_name'] = "Please Enter first name"
      flag = false
    }

    if (!fields.last_name) {
      error['last_name'] = "Please Enter last name"
      flag = false
    }

    if (!fields.profile_photo) {
      error['profile_photo'] = "Please Select Photo"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please Select Status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      const formData = new FormData();
      if(profile_photo){
        formData.append('profile_photo', profile_photo);
      }
      formData.append('fields', JSON.stringify({...fields, lang_code:'EN'}));
      configuration.postFormDataAPI({url:'passenger/update', params:formData}).then((data) => {
        if(data.status === 200){
          history.push('/passenger')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    if(field === 'profile_photo'){
      setPhoto(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
    // setFields({ ...fields, [field]: e.target.value })
  }

  function cancleImage(field, e) {
    setPhoto(null);
    setFields({ ...fields, [field]: '' })
  }

  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'passenger/list', params:{passenger_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editPassenger} />
      <AddEditSection title="Edit Passenger" path="/passenger" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} cancleImage={cancleImage} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
