import PropTypes from "prop-types";
import React, {useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Label from "../../components/Form/Label";
import labels from "../../constants/OTP";
// import common from "../../constants/Common";
import configuration from '../../config';
import { toast } from 'react-toastify';
import {reactLocalStorage} from 'reactjs-localstorage';


function About() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 page_bg">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="../image/logo.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 text_theme">
          
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 box_bg">
          <form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-700 text-base text_theme"
              >
              </label>
              <div class="mt-9">
                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  
                </div>
              </div>
            </div>
            <div>
              
            </div>
          </form>
          <div className="text-center mt-6 text-base text_theme">
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
