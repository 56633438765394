import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import labels from "../../constants/Dashboard";
import configuration from "../../config";
import { ArchiveIcon, InformationCircleIcon, DocumentRemoveIcon, UserIcon, MailIcon, AnnotationIcon, MailOpenIcon, InboxInIcon, SpeakerphoneIcon, OfficeBuildingIcon, CheckCircleIcon, UserCircleIcon } from '@heroicons/react/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup, faUser, faUserTie, faUserCheck, faPerson, faBuilding, faHandshake, faCar, faCalendar, faMotorcycle, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import { toast } from "react-toastify";
import DataTable from "../../components/MDataTable";


import { Line, Bar } from "react-chartjs-2";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const Tabs = ({ color }) => {

  const [openTab, setOpenTab] = React.useState(1);

  const options = {
    maintainAspectRatio: true,	// Don't maintain w/h ratio
  }

  const [Statistics, setStatistics] = useState([]);
  const [years, setYears] = useState([]);
  const [currency, setCurrency] = useState('$');
  const [salesYear, setSalesYear] = useState('');
  const [profitYear, setProfitYear] = useState('');
  const [Sales, setSales] = useState([]);
  const [Profit, setProfit] = useState([]);
  const [subStatistics, setSubStatistics] = useState([]);
  
  const [businessLabel, setBusinessLabel] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [driverLabel, setDriverLabel] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [serviceLabel, setServiceLabel] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  // For Dashboard

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const columns = [
    { title: "ID", field: "trip_id"},
    {
      title: "Passanger",
      field: "p_first_name",
      render: (rowData) => (
        <span>
          {rowData.p_first_name} {rowData.p_last_name}
        </span>
      ),
    },
    {
      title: "Driver",
      field: "d_first_name",
      render: (rowData) => (
        (rowData.d_first_name && rowData.d_first_name !== '')?<span>
          {rowData.d_first_name} {rowData.d_last_name}
        </span>:<span> Not Assign </span>
      ),
    },
    {
      title: "Service",
      field: "trip_id",
      render: (rowData) => (
        <span>
          {rowData.service_info.title}
        </span>
      ),
    },
    { title: "Trip Date", field: "created_at" },
    {
      title: "Subtotal",
      field: "subtotal",
      render: (rowData) => (
        <span>
          {rowData.currency} {Number(rowData.subtotal).toFixed(2)}
        </span>
      ),
    },
    { title: "KM ", field: "formatted_total_distance" },
    {
      title: "Total",
      field: "total",
      render: (rowData) => (
        <span>
          {rowData.currency} {Number(rowData.total).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        let status = rowData.status;
        status = status.split('_').join(' ');
        return (<span
          className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 capitalize"
        >
          {status}
        </span>)},
    },
  ];

  const [data, setData] = useState([]);

  function getSubSummary(user_type) {
    configuration.getAPI({ url: 'dashboard/sub-summary', params: {user_type} }).then((data) => {
      setSubStatistics(data.payload || []);
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  
  function totalSales(type, year){
    configuration.getAPI({ url: 'dashboard/total-sales', params: {type, year} }).then((data) => {
      if(type === 'sales'){
        setSales(data.payload || []);
      }
      if(type === 'profit'){
        setProfit(data.payload || []);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  function topPerformings(type){
    configuration.getAPI({ url: 'dashboard/top-performings', params: {type} }).then((data) => {
      if(type === 'driver'){
        setDriverData(data.payload.data || []);
        setDriverLabel(data.payload.labels || []);
      }
      if(type === 'service'){
        setServiceData(data.payload.data || []);
        setServiceLabel(data.payload.labels || []);
      }
      if(type === 'business'){
        setBusinessData(data.payload.data || []);
        setBusinessLabel(data.payload.labels || []);
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  useEffect(() => {
    setSalesYear(new Date().getFullYear())
    setProfitYear(new Date().getFullYear())
    const startYear = 2022;
    const endYear = new Date().getFullYear();
    for(let i = startYear; i<=endYear; i++){
        years.push(i);
    }
    setYears(years)
    configuration.getAPI({ url: 'dashboard/summary', params: {} }).then((data) => {
      setStatistics(data.payload || []);
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({url:'trip/list', params:{status:['picked_up', 'started', 'round_started']}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    getSubSummary('passenger')
    topPerformings('driver')
    topPerformings('service')
    topPerformings('business')
    totalSales('sales', new Date().getFullYear())
    totalSales('profit', new Date().getFullYear())
  }, []);


  function handleChangeTab(type, id, e){
    console.log(id)
    setSubStatistics([]);
    getSubSummary(type)
    setOpenTab(id);
  }

  function handleChange(type, e){
    if(type === 'sales'){
      setSalesYear(e.target.value);
    } else {
      setProfitYear(e.target.value)
    }
    // totalSales(type, e.target.value);
  }
  function onSelectionChange(){

  }

  const Box = ({ children }) => (
      <div key={children} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
        <dt className="text-base font-medium text-red-600 truncate">{children}</dt>
        <dd className="mt-1 text-3xl font-semibold text-gray-900">{children}</dd>
      </div>
  );
  return (
    <>
      <Breadcrumb module={labels.dashboard} />

      <div className=" px-3 py-3 grid md:grid-cols-1 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Statistics
            </h2>
          </div>
          <div className="border-t border-gray-200">
            <div className="mb-5 chart-wrapper">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 flex-row border-b border-gray-200 font-medium"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 1
                        ? "text-indigo-600 border-b-2 border-green-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab( 'passenger', 1, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Passengers
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 2
                        ? "text-indigo-600 border-b-2 border-green-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('driver', 2, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Drivers
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 3
                        ? "text-indigo-600 border-b-2 border-green-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('trip', 3, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Trips
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
                  <a
                    className={
                      "text-lg text-base px-5 py-3 block " +
                      (openTab === 4
                        ? "text-indigo-600 border-b-2 border-green-600 bg-" + color + "-600"
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={e => handleChangeTab('subscription', 4, e)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    Subscriptions
                  </a>
                </li>
              </ul>

              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0)?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item)?item.stat:<Skeleton width={40} height={25}/>}</dd>
                      </div>
                    ))}
                  </dl>
                  :<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                  </dl>}
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0)?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item)?item.stat:<Skeleton width={40} height={25}/>}</dd>
                      </div>
                    ))}
                  </dl>
                  :<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                  </dl>}
              </div>
              <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0)?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item)?item.stat:<Skeleton width={40} height={25}/>}</dd>
                      </div>
                    ))}
                  </dl>
                :<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                  </dl>}
              </div>
              <div className={openTab === 4 ? "block" : "hidden"} id="link1">
                {(subStatistics.length > 0)?
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    {subStatistics.map((item) => (
                      <div key={item.name} className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden">
                        <dt className="text-base font-medium text-indigo-600 truncate">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold text-gray-900">{(item)?item.stat:<Skeleton width={40} height={25}/>}</dd>
                      </div>
                    ))}
                  </dl>
                :<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 px-4">
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                    <Skeleton wrapper={Box}/>
                  </dl>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" px-3 py-3 grid md:grid-cols-1 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Current On Going Trips
            </h2>
          </div>
          <div className="border-t border-gray-200 mr-5 ml-5">
            
            <DataTable
              columns={columns}
              data={data}
              disabledCheckbox={true}
              onSelectionChange={onSelectionChange}
            />
          </div>
        </div>
      </div>

      <div className=" px-3 py-3 grid md:grid-cols-3 gap-6">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
             Trips this Month
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">This shows all trips this month</p>
                </div>
              </div>
            </div>
            <div className="mt-5 chart-wrapper">
              <Line data={{
                labels: serviceLabel,
                datasets: [
                  {
                    label: "Services",
                    data: serviceData,
                    fill: true,
                    backgroundColor: "rgb(251, 191, 36,0.2)",
                    borderColor: "rgba(251, 191, 36,1)"
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Services</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Top Performing Drivers
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">This shows projected from scheduled trips and average trips</p>
                </div>
              </div>
            </div>
            <div className="mt-5 chart-wrapper">
              <Line data={{
                labels: driverLabel,
                datasets: [
                  {
                    label: "Drivers",
                    data: driverData,
                    fill: true,
                    backgroundColor: "rgb(0,100,0,0.2)",
                    borderColor: "rgba(25, 114, 18, 1)"
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Drivers</p>
            </div>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              Projected Earnings
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">This shows projected earnings for next 30 days</p>
                </div>
              </div>
            </div>
            <div className="mt-5 chart-wrapper">
              <Line data={{
                labels: businessLabel,
                datasets: [
                  {
                    label: "Bussiness",
                    data: businessData,
                    fill: true,
                    backgroundColor: "rgb(251, 191, 36,0.2)",
                    borderColor: "rgba(251, 191, 36,1)"
                  }
                ]
              }} />
              <p className="text-center w-full pt-4 ">Services</p>
            </div>
          </div>
        </div>
      </div>

    </>
  );

}

export default function TabsRender() {
  return (
    <>
      <Tabs color="white" />
    </>
  );
}
