import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/AppUser";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    {
      title: "ID",
      field: "user_id",
    },
    { title: "Name", field: "first_name",
      render: (rowData) => (
        <span>
          {rowData.first_name} {rowData.last_name}
        </span>
      )
    },
    { title: "Email", field: "email" },
    {
      title: "Current Profile",
      field: "current_profile",
      render: (rowData) => (
        <span className="capitalize">
          {rowData.current_profile}
        </span>
      ),
    },
    { title: "Sign up date", field: "created_at" },
  ];

  const btnGroups = [];
  const [data, setData] = useState([]);
  
  useEffect(() => {
    configuration.getAPI({url:'app-user/list', params:{user_type:'potential'}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.WebUsers} />
      <ListSection
        captionTitle="View Web Users"
        columns={columns}
        data={data}
        disabledCheckbox={true}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
