import React, { useEffect, useState } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/EmailTemplate";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// Edit Email Template
let Id = "";
function Edit({ history }) {
  const [fieldArr, setFieldArr] = useState([])
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [languages, setLanguages] = useState([])
  const [description, setDescription] = useState({})

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const isSendOpt = [
    { id: 1, title: "Yes", value: "true" },
    { id: 2, title: "No", value: "false" }
  ];
  const columnArr = [
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    { id: 1, title: "Code", name: "template_code", type: "TextInput" },
    { id: 2, title: "From Name", name: "from_name", type: "TextInput" },
    { id: 3, title: "From Email", name: "from_email", type: "TextInput" },
    { id: 4, title: "Is Send", name: "is_send", type: "Dropdown", options: isSendOpt },
    { id: 5, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'email-template/list', params: { email_template_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    getlanguages();
  }, []);

  function getlanguages() {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let subjectArr = []
        let descriptionArr = []
        UND.each(data.payload, (lang, ind) => {
          subjectArr.push({ id: ind, title: "Email Subject[" + lang.title + "]", name: "email_subject_" + lang.code, type: "TextInput" })
          descriptionArr.push({ id: ind, title: "Description[" + lang.title + "]", name: "description_" + lang.code, type: "Editor", is_full_screen:true })
        })
        const fullCoulnmArr = columnArr.concat(subjectArr, descriptionArr);
        setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleChange(field, e) {
    if (field.search("description") >= 0) {
      setDescription({ ...description, [field]: e.target.getContent() })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter title"
      flag = false
    }
    if (!fields.template_code) {
      error['template_code'] = "Please enter template code "
      flag = false
    }
    if (!fields.from_name) {
      error['from_name'] = "Please enter from name "
      flag = false
    }
    if (!fields.from_email) {
      error['from_email'] = "Please enter from email "
      flag = false
    }
    if (!fields.is_send) {
      error['is_send'] = "Please select is send"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }

    UND.each(languages, (lang, ind) => {
      if (!fields['email_subject_' + lang.code]) {
        error['email_subject_' + lang.code] = "Please enter email subject in " + lang.title
        flag = false
      }
      // if (!fields['description_' + lang.code]) {
      //   error['description_' + lang.code] = "Please enter description in " + lang.title
      //   flag = false
      // }
    })

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    console.log(description)
    // const fullObj = {
      //   ...fields,
      //   ...description
      // };
      let fullObj = UND.extend(fields, description);
      console.log(fullObj)
    if (handleValidation()) {
      configuration.postAPI({ url: 'email-template/update', params: fullObj }).then((data) => {
        if (data.status === 200) {
          history.push('/email-template')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  return (
    <>
      <Breadcrumb module={labels.editEmailTemplate} />
      <AddEditSection
        title="Edit Email Template"
        path="/email-template"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
